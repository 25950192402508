export const venue_type = [
    {value: "graduations", label: "Graduations"},
    {value: "music_concerts", label: "Music Concerts"},
    {value: "awards_ceremonies", label: "Awards Ceremonies"},
    {value: "conventions", label: "Conventions"},
    {value: "dramas", label: "Dramas"},
    {value: "beauty_pageants", label: "Beauty Pageants"},
    {value: "cocktails", label: "Cocktails"},
    {value: "lunch_dinner", label: "Lunch/Dinner"},
    {value: "exhibitions", label: "Exhibitions"},
    {value: "fairs", label: "Fairs"},
    {value: "launching_ceremonies", label: "Launching Ceremonies"},
    {value: "sing_alongs", label: "Sing Alongs"},
    {value: "wedding_receptions", label: "Wedding Receptions"},
    {value: "parties", label: "Parties"},
    {value: "alms_givings", label: "Alms Givings"},
    {value: "seminars", label: "Seminars"},
    {value: "conferences", label: "Conferences"},
    {value: "annual_general_meetings", label: "Annual General meetings"},
    {value: "workshops", label: "Workshops"},
    {value: "vip_refreshments", label: "VIP Refreshments"},
    {value: "examinations", label: "Examinations"},
    {value: "cinemas", label: "Cinemas"},
    {value: "dressing", label: "Dressing"},
    {value: "meeting", label: "Meeting"},
    {value: "auction", label: "Auction"}
  ]