import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useParams, useLocation } from "react-router-dom";
import { instance } from "../../config/instance";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../assets/img/logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import axios from "axios";

const GenerateInvoice = (invoiceNumber) => {
  // html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
  //   const imgData = canvas.toDataURL("image/png", 1.0);
  //   const pdf = new jsPDF({
  //     orientation: "portrait",
  //     unit: "pt",
  //     format: [212, 292],
  //   });
  //   // pdf.internal.scaleFactor = 1;
  //   const imgProps = pdf.getImageProperties(imgData);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //   pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save(`${invoiceNumber}_Paid.pdf`);
  // });

  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const link = document.createElement("a");
    link.href = imgData;
    link.download = `${invoiceNumber}_Paid.png`;
    link.click();
  });
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Payment_Success = () => {
  const { bid, id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [currentDateAndTime, setCurrentDateAndTime] = useState("");

  // setCurrentDateAndTime(new Date().toLocaleString());

  const UpdateInvoiceById = async () => {
    setLoading(true);
    const data = {
      invoiceId: id,
      status: "Paid",
    };

    try {
      const response = await instance.put(
        "/customer/invoice/update-status",
        data
      );
      setLoading(false);
    } catch (error) {
      console.log("This is error in APi: ", error);
    }
  };

  const UpdateBookingStaus = async () => {
    setLoading(true);
    const data = {
      bookingId: bid,
      status: "Advance Paid",
    };

    try {
      const response = await instance.put(
        "/customer/booking/update-status",
        data
      );

      setLoading(false);
    } catch (error) {
      console.log("This is error in APi: ", error);
    }
  };

  const getInvoiceDetails = async () => {
    try {
      const response = await instance.get(`/customer/invoice/${id}`);
      setInvoice(response.data[0]);
    } catch (error) {
      console.log("This is error in APi: ", error);
    }
  };

  const getPaymentGatewayDetails = async () => {
    try {
      const response = await instance.post(`customer/payment/success/${id}`);
      // console.log(response.data);
    } catch (error) {
      console.log("This is error in APi: ", error);
    }
  };

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    getInvoiceDetails();
  }, [id, loading]);

  if (!id) return <h1>Invalid Payment</h1>;

  useEffect(() => {
    UpdateInvoiceById();
    UpdateBookingStaus();
    getPaymentGatewayDetails();
  }, [id]);

  useEffect(() => {
    setCurrentDateAndTime(new Date().toLocaleString());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div
          className="card shadow-lg "
          style={{
            maxWidth: "600px",
            width: "400px",
            textAlign: "center",
            borderRadius: "10px",
          }}
        >
          <div className="card-body" id="invoiceCapture">
            <div className="mb-5">
              <img src={logo} alt="logo" style={{ width: "200px" }} />
            </div>

            {/* <FaCheckCircle style={{ color: "green", fontSize: "50px" }} /> */}
            <h3 className="card-title">Payment Successful!</h3>
            <p className="card-text">Thank you for your payment.</p>

            <div id="invoiceCapture">
              <div className="row  mb-3">
                <div className="col text-start">
                  <p className="fw-bold">Amount Paid :</p>
                  <p className="fw-bold">Payment Status :</p>
                  <p className="fw-bold">Reservation Id :</p>
                  <p className="fw-bold">Invoice Id :</p>
                  <p className="fw-bold">Date :</p>
                </div>
                <div className="col text-end">
                  <p>
                    LKR{" "}
                    {invoice &&
                      !isNaN(parseFloat(invoice.due_amount)) &&
                      parseFloat(invoice.due_amount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </p>
                  <p>{invoice && invoice.status}</p>
                  <p>{bid && bid}</p>
                  <p>{id && id}</p>
                  <p>{currentDateAndTime}</p>
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn"
            style={{ backgroundColor: "#c29124", color: "#fff" }}
            onClick={() => GenerateInvoice(id)}
          >
            Download Receipt
          </button>
        </div>
      )}
    </div>
  );
};

export default Payment_Success;
