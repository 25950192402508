import React from 'react'
import './copyright.scss'

const Copyright = () => {
  return (
    <div id='copyright'>
      <p>© Copyright {new Date().getFullYear()}. BMICH. All Rights Reserved | <a href="https://visionarydv.com/" target='_blank' style={{color:'inherit'}}>VDV</a></p>
    </div>
  )
}

export default Copyright