import axios from "axios";

// Staging
// export const base_url = 'https://phpstack-1185764-4177397.cloudwaysapps.com/api';
// export const base_url = 'https://phpstack-1185764-4422566.cloudwaysapps.com/api';

// Live App
export const base_url = 'https://booking.bmich.com/api/';

// Production
// export const base_url = 'https://phpstack-1185764-4420574.cloudwaysapps.com/api';

// Local
// export const base_url = 'http://localhost:5000/api';

export const instance = axios.create({
    baseURL: base_url,
    timeout: 100000,
    withCredentials: true,
});