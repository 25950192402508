import React, { useState, useEffect } from 'react';
import { useBooking } from './BookingContext';
import './FloatingBox.css';

const FloatingBox = () => {
    const { bookingData, clearBookingData } = useBooking();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [flipped, setFlipped] = useState(false);

    // Toggle the expanded state on click
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Show popup message every 30 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setShowPopup(true);

            // Hide the popup after a few seconds
            setTimeout(() => {
                setShowPopup(false);
            }, 4000); // Popup will disappear after 4 seconds
        }, 30000); // Show the popup every 30 seconds

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    // Flip the circle every 5 seconds
    useEffect(() => {
        const flipInterval = setInterval(() => {
            setFlipped(prev => !prev);
        }, 5000); // Flip every 5 seconds

        return () => clearInterval(flipInterval); // Cleanup the interval on component unmount
    }, []);

    // Only show the circle if there are bookings
    if (bookingData.length === 0) {
        return null;
    }

    return (
        <div>
            {/* Popup message */}
            {showPopup && (
                <div style={popupContainerStyle}>
                    <div style={popupStyle}>Click to view your bookings</div>
                    <div style={triangleStyle}></div>
                </div>
            )}

            <div
                className={`circle ${flipped ? 'flipped' : ''}`}
                onClick={handleToggleExpand}
            >
                <div className="circle-front">
                    Your Bookings
                </div>
                <div className="circle-back">
                <div className="circle-number"> {bookingData.length}</div>
                </div>
            </div>

            {isExpanded && bookingData.length > 0 && (
                <div style={expandedBoxStyle}>
                    <button style={minimizeButtonStyle} onClick={handleToggleExpand}>
                        Hide
                    </button>
                    <div style={scrollStyle}>
                        {bookingData.map((booking, index) => (
                            <div key={index} style={bookingItemStyle}>
                                <span style={indexStyle}>{index + 1}. </span>
                                <span>
                                    Start: {new Date(booking.options[0].time.start).toLocaleString()}<br />
                                    End: {new Date(booking.options[0].time.end).toLocaleString()}
                                </span>
                            </div>
                        ))}
                    </div>
                    <button style={clearButtonStyle} onClick={clearBookingData}>
                        Clear All Bookings
                    </button>
                </div>
            )}
        </div>
    );
};

// Styles for the component
const popupContainerStyle = {
    position: 'fixed',
    top: 'calc(55% - 120px)', // Positioned above the circle
    right: '20px',
    textAlign: 'center',
    zIndex: 1001,
};

const popupStyle = {
    display: 'inline-block',
    padding: '8px 12px',
    backgroundColor: '#044ec1',
    color: '#fff',
    borderRadius: '8px',
    fontSize: '15px',
    opacity: 0.9,
    position: 'relative',
};

const triangleStyle = {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #333', // Same color as the popup background
    margin: '0 auto',
    position: 'absolute',
    top: '100%', // Position it at the bottom of the popup
    left: '90%',
    transform: 'translateX(-50%)',
};

const expandedBoxStyle = {
    position: 'fixed',
    top: '10px',
    right: '10px',
    width: '300px',
    maxHeight: '400px',
    padding: '10px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    overflowY: 'auto',
    cursor: 'default',
};

const scrollStyle = {
    maxHeight: '100%',
    overflowY: 'auto',
};

const bookingItemStyle = {
    padding: '5px',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
};

const indexStyle = {
    marginRight: '10px',
    fontWeight: 'bold',
};

const minimizeButtonStyle = {
    borderRadius: '4px',
    top: '5px',
    right: '5px',
    background: '#be6900',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: 'white',
    padding: '5px 10px 5px 10px',
};

const clearButtonStyle = {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: '#be6900',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
};

export default FloatingBox;
