import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import header from "./header.jpg";
import footer from "./footer.jpg";
import { instance } from "../../config/instance";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";

const GenerateInvoice = (invoiceNumber) => {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [612, 692],
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${invoiceNumber}.pdf`);
  });
};

const InvoicePay = () => {
  const { id } = useParams();

  // console.log("This is id = ", id);

  const [sessionID, setSessionID] = useState(null);

  const [currency, setCurrency] = useState("LKR");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [InvoiceData, setInvoiceData] = useState({});
  const [details, setDetails] = useState([]);
  const [sendTo, setSendTo] = useState({});
  const [charges, setCharges] = useState({});

  const [Loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  // Function to calculate total cost and format it
  const calculateAndFormatTotalCost = (cost, quantity) => {
    // Calculate total cost by multiplying cost with quantity
    const totalCost = !isNaN(parseFloat(cost))
      ? parseFloat(cost) * quantity
      : NaN;

    // Format total cost using toLocaleString if it's a valid number
    return !isNaN(totalCost)
      ? totalCost.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "Invalid cost";
  };

  const showPaymentPage = () => {
    // const { Checkout } = window;
    // console.log("This is checkout = ", Checkout);

    // Checkout.showEmbeddedPage("#embed-target");
    setTimeout(() => {
      window.Checkout.configure({
        session: {
          id: sessionID,
        },
      });

      // window.Checkout.showEmbeddedPage("#embed-target");
      window.Checkout.showPaymentPage();
    }, 1000);
  };

  const GetInvoiceData = async (invoiceNumber) => {
    try {
      const res = await instance.get(`/customer/invoice/get/${invoiceNumber}`);
      setInvoiceData(res.data[0]);
      setDetails(JSON.parse(res.data[0].details));
      setSendTo(JSON.parse(res.data[0].send_to));
      setCharges(JSON.parse(res.data[0].charges));
    } catch (error) {
      console.error("Error getting invoice data:", error);
    }
  };

  useEffect(() => {
    GetInvoiceData(id);
  }, []);

  useEffect(() => {
    const { Checkout } = window;

    if (!Checkout) return;

    if (!InvoiceData.due_amount) return;
    if (!id) return;
    if (!InvoiceData.booking_id) return;

    async function initializeCheckoutSession() {
      try {
        const response = await instance.post("/customer/payment/session", {
          orderDescription: "Testing order",
          // amount: "300",
          amount: InvoiceData.due_amount,
          InvoiceId: id,
          bookingId: InvoiceData.booking_id,
        });

        const { sessionID, ok, message } = response.data;

        // console.log("This is session id : ", sessionID);
        if (sessionID) setSessionID(sessionID);

        if (!ok) return alert(message);

        Checkout.configure({
          session: {
            id: sessionID,
          },
        });
      } catch (error) {
        console.log("Error occured in initializeCheckout ", error);
        console.error("Thsis is error = ", error);
      }
    }

    initializeCheckoutSession();
  }, [InvoiceData.due_amount, id, InvoiceData.booking_id]);

  useEffect(() => {
    if (!InvoiceData.created_at) return;

    setDateOfIssue(InvoiceData.created_at.split("T")[0]);
  }, [InvoiceData]);

  return (
    <div className="container mt-5">
      <div id="invoiceCapture">
        <div className="mx-auto text-center">
          <img
            src={header}
            style={{
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "200px",
              height: "200px",
              objectFit: "contain",
            }}
            alt=""
          />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
          <div className="">
            <h4 className="fw-bold my-2">Proforma Invoice</h4>
            <h6 className="fw-bold text-secondary mb-1">
              Invoice #: {id || ""}
            </h6>
          </div>
          <div className="my-auto">
            <span className="fs-5">Amount Due : </span>
            <h5 className="fw-bold d-block text-secondary">
              {currency}{" "}
              {!isNaN(parseFloat(InvoiceData.due_amount)) &&
                parseFloat(InvoiceData.due_amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </h5>
          </div>
        </div>
        <div className="p-4">
          <Row className="mb-4">
            <Col md={4}>
              <div className="fw-bold">Billing Address :</div>
              <div>{sendTo.organization}</div>
              <div>{sendTo.name || ""}</div>
              <div>{sendTo.address || ""}</div>
              <div>{sendTo.email || ""}</div>
            </Col>
            <Col md={4}>
              <div className="fw-bold mt-1">Date Of Issue:</div>
              <div>{dateOfIssue || ""}</div>
            </Col>
            <Col md={4}>
              <div className="fw-bold mt-1">Reservation ID:</div>
              <div>{InvoiceData.booking_id || ""}</div>
            </Col>
          </Row>
          {details.map((addon, index) => {
            return (
              <div key={index}>
                <div className="d-flex my-3 align-items-baseline mb-2">
                  <h6 className="fw-bold me-2 mb-0">Booking Date:</h6>
                  <p className="mb-0">
                    {new Date(addon.dateOfBooking).toLocaleDateString("en-IN", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                </div>
                {addon.addons.map((addon, i) => {
                  return (
                    <React.Fragment key={`${index}-${i}`}>
                      <div className="d-flex my-3 align-items-baseline mb-2">
                        <h6 className="fw-bold">Venue and time slot:</h6>
                        <div className="d-flex ms-2">
                          <div>
                            <span>
                              {sendTo.venue_name ? sendTo.venue_name : ""}
                            </span>
                          </div>
                          <p className="mb-0 ms-2">
                            ({`${addon.start_time} - ${addon.end_time}`})
                          </p>
                        </div>
                      </div>
                      <Table className="mb-0">
                        <thead>
                          <tr>
                            <th>QTY</th>
                            <th>DESCRIPTION</th>
                            <th className="text-end">PRICE</th>
                            <th className="text-end">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addon.addons.map((addonItem, j) => {
                            return (
                              <tr key={`${index}-${i}-${j}`}>
                                <td>{addonItem.quantity}</td>
                                <td>{addonItem.add_ons_description}</td>
                                <td className="text-end">
                                  {currency}{" "}
                                  {parseFloat(addonItem.cost).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td className="text-end">
                                  {currency}{" "}
                                  {calculateAndFormatTotalCost(
                                    addonItem.cost,
                                    addonItem.quantity
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}

          <Table>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              {charges.discount !== "0.00" && (
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: "200px" }}>
                    DISCOUNT
                  </td>
                  <td className="text-end" style={{ width: "200px" }}>
                    {currency}{" "}
                    {!isNaN(parseFloat(charges.discount)) &&
                      parseFloat(charges.discount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
              )}

              <tr className="text-end">
                <td></td>
                <td className="fw-bold" style={{ width: "200px" }}>
                  SUBTOTAL
                </td>
                <td className="text-end" style={{ width: "200px" }}>
                  {currency}{" "}
                  {!isNaN(parseFloat(charges.subtotal)) &&
                    parseFloat(charges.subtotal).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>

              {charges.sccl_tax !== 0.0 && InvoiceData.VAT_registration_no ? (
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: "200px" }}>
                    SSCL
                  </td>
                  <td className="text-end" style={{ width: "200px" }}>
                    {currency}{" "}
                    {!isNaN(parseFloat(charges.sccl_tax)) &&
                      parseFloat(charges.sccl_tax).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {charges.vat_tax !== 0.0 && InvoiceData.VAT_registration_no ? (
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: "200px" }}>
                    VAT {charges.vat_tax}%{" "}
                  </td>
                  <td className="text-end" style={{ width: "200px" }}>
                    {currency}{" "}
                    {!isNaN(parseFloat(charges.vat_tax)) &&
                      parseFloat(charges.vat_tax).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
              ) : (
                " "
              )}

              <tr className="text-end">
                <td></td>
                <td className="fw-bold" style={{ width: "200px" }}>
                  NET TOTAL{" "}
                  {InvoiceData.VAT_registration_no ? " " : "(WITH TAXES)"}
                </td>
                <td className="text-end" style={{ width: "200px" }}>
                  {currency}{" "}
                  {!isNaN(parseFloat(charges.total)) &&
                    parseFloat(charges.total).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>

              <tr className="text-end">
                <td></td>
                <td className="fw-bold" style={{ width: "200px" }}>
                  REFUNDABLE DEPOSIT
                </td>
                <td className="text-end" style={{ width: "200px" }}>
                  {currency}{" "}
                  {!isNaN(parseFloat(charges.refundable_deposit)) &&
                    parseFloat(charges.refundable_deposit).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                </td>
              </tr>

              <tr className="text-end">
                <td></td>
                <td className="fw-bold" style={{ width: "200px" }}>
                  DUE AMOUNT
                </td>
                <td className="text-end" style={{ width: "200px" }}>
                  {currency}{" "}
                  {!isNaN(parseFloat(InvoiceData.due_amount)) &&
                    parseFloat(InvoiceData.due_amount).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <img
          src={footer}
          alt="Footer"
          style={{
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        />
      </div>
      <div className="pb-4 px-4">
        <Row>
          <Col md={6}>
            <Button
              variant="outline-primary"
              className="d-block w-100 mt-3 mt-md-0"
              onClick={() => GenerateInvoice(id)}
            >
              <BiCloudDownload
                style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                className="me-2"
              />
              Download Copy
            </Button>
          </Col>
          <Col md={6}>
            <Button
              variant="primary"
              onClick={showPaymentPage}
              className="d-block w-100"
            >
              Pay {currency}{" "}
              {!isNaN(parseFloat(InvoiceData.due_amount)) &&
                parseFloat(InvoiceData.due_amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InvoicePay;
