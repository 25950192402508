import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FileInput,
  Radio,
  TextInput,
} from "./form-element/FormElement";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik, useFormikContext } from "formik";
import { BookingFormValidation } from "../../validation/FormValidation";
import PricingMoreDetails from "./PricingMoreDetails";
import { instance } from "../../config/instance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const MainBookingForm = (props) => {
  // console.log('Props:', props);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    textAlign: "center",
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [reservationCount, setReservationCount] = useState(950);
  const [eventStartTime, setEventStartTime] = useState(null);
  const [bookingNumber, setBookingNumber] = useState("EVN-2022-000001");
  const [vatVerified, setVatVerified] = useState(null); // Initial state set to null
  const [vatCheck, setVatCheck] = useState(false);

  const handleVatCheck = (e) => {
    const value = e.target.value === "true"; // Convert string to boolean
    setVatVerified(value);
    setVatCheck(value); // Optionally, if you also want to update vatCheck state
  };

  const navigate = useNavigate();
  const [vat_image, setVatImage] = useState(null);

  const {
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      name_of_organization_individual: "",
      account_name: "",
      account_number: "",
      bank_name: "",
      bank_code: "",
      branch_name: "",
      branch_code: "",
      swift_code: "",
      contact_person: "",
      address: "",
      telephone_no: "",
      mobile_no: "",
      email: "",
      name_of_the_event: "",
      // venue_name: "",
      venue_name: props.venue?.name || "", 
      date: "",
      end_date: "", 
      time: "",
      event_type: [],
      setup: [],
      public_performance_approval: "",
      chief_guest: "",
      expected: "",
      guaranteed: "",
      VAT_registration_no: "",
      VAT_copy: "",
      other: "",
    },
    onSubmit: (values) => submit(values),
    validationSchema: BookingFormValidation,
  });


  const submit = async (e) => {
    e.preventDefault();

    let error = 0;
    Object.keys(meal).forEach((key) => {
      if (meal[key].check && meal[key].time === "") {
        error += 1;
        return document.getElementById(key + "_time").focus();
      }
    });

    if (error === 0) {
      const refund_drawn = {
        account_name: values.account_name,
        account_number: values.account_number,
        bank_name: values.bank_name,
        bank_code: values.bank_code,
        branch_name: values.branch_name,
        branch_code: values.branch_code,
      };

      // Check Not Compulsory Fields
      if (
        values.swift_code !== undefined &&
        values.swift_code !== null &&
        values.swift_code !== ""
      ) {
        refund_drawn.swift_code = values.swift_code;
      }

      const contact_person = {
        name: values.contact_person,
        address: values.address,
        email: values.email,
      };

      // Check Not Compulsory Fields
      if (
        values.telephone_no !== undefined &&
        values.telephone_no !== null &&
        values.telephone_no !== ""
      ) {
        contact_person.telephone_no = values.telephone_no;
      }

      if (
        values.mobile_no !== undefined &&
        values.mobile_no !== null &&
        values.mobile_no !== ""
      ) {
        contact_person.mobile_no = values.mobile_no;
      }

      // Checking video_wall Objet fields

      // console.log("Initial values:", values);
      const video_wall = {};

      const fields = ["name_of_the_event", "venue_name", "date","end_date", "time"];
      
      fields.forEach((field) => {
        if (values[field] !== undefined && values[field] !== null && values[field] !== "") {
          video_wall[field] = values[field];
        }
      });
      // console.log("Video Wall:", video_wall);
      // fields.forEach((field) => {
      //   if (
      //     values[field] !== undefined &&
      //     values[field] !== null &&
      //     values[field] !== ""
      //   ) {
      //     video_wall[field] = values[field];
      //   }
      // });

      let meal_plan = [];

      Object.keys(meal).forEach((key) => {
        if (meal[key].check) {
          meal_plan.push({ [key]: meal[key].time });
        }
      });

      const number_of_guests = {
        expected: values.expected,
        guaranteed: values.guaranteed,
      };

      const formData = new FormData();

      formData.append("name", values.name_of_organization_individual);

      formData.append("refund_drawn", JSON.stringify(refund_drawn));

      formData.append("contact_person", JSON.stringify(contact_person));

      // formData.append("venue_name", props.venue.name);

      // if (props.venue && props.venue.name) {
      //   formData.append("venue_name", props.venue.name);
      //   console.log("venue_name is = ", props.venue.name);
      // } else {
      //   console.warn("Venue name is not available.");
      // }

      formData.append("video_wall", JSON.stringify(video_wall));

      formData.append(
        "event_type",
        JSON.stringify({ ...values.event_type, other: values.other })
      );

      formData.append("setup", JSON.stringify(values.setup));

      formData.append("meal_plan", JSON.stringify(meal_plan));



      formData.append(
        "public_performance_approval",
        values.public_performance_approval
      );

      formData.append("chief_guest", values.chief_guest);

      formData.append("number_of_guests", JSON.stringify(number_of_guests));

      // Checking Not Compulsory Fields
      if (
        values.VAT_registration_no !== undefined &&
        values.VAT_registration_no !== null &&
        values.VAT_registration_no !== ""
      ) {
        formData.append("VAT_registration_no", values.VAT_registration_no);
      }

      if (vat_image !== undefined && vat_image !== null) {
        formData.append("VAT_copy", vat_image);
      }

      formData.append("venue_id", props.venue.id);



      formData.append("BOOKING_DATA", JSON.stringify(props.bookingData));
      

      formData.append("booking_id", bookingNumber);

      // Arrangements and Dismantling

      formData.append("needArrAndDis", props.needArrAndDis ? 1 : 0);
      if (props.needArrAndDis) {
        formData.append(
          "arrangementsDismantling",
          JSON.stringify(props.arrangementsDismantling)
        );
        formData.append("arrangementCost", props.arrangementCost);
        formData.append("dismantlingCost", props.dismantlingCost);
      } else {
        formData.append(
          "arrangementsDismantling",
          props.arrangementsDismantling
        );
        formData.append("arrangementCost", 0);
        formData.append("dismantlingCost", 0);
      }

      const newCount = reservationCount + 1;
      setReservationCount(newCount);
      localStorage.setItem("reservation_Count", newCount.toString());

      formData.append("reservation_count", reservationCount);

          // Log FormData entries
    // console.log("FormData Entries:");
    formData.forEach((value, key) => {
      // console.log(`${key}:`, value);
    });

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const res = await instance.post(
          "/customer/venue-booking",
          formData,
          config
        );

        navigate("/booking-success", { state: { fromBookingPage: true } });
        // alert("Booking Placed Successfully");
      } catch (err) {
        console.log("This is the error why this booking failed", err);
        alert("Booking Failed");
      }
    }
  };

  const margin_bottom = "mb-3";
  

  const fields = {
    name_of_organization_individual: {
      label: "Name of Organization/Individual",
      _id: "name_of_organization_individual",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "name_of_organization_individual",
      handleBlur: handleBlur,
      touched: touched["name_of_organization_individual"],
      error: errors["name_of_organization_individual"],
      onChange: handleChange,
      value: values["name_of_organization_individual"],
      required: true,
    },
    account_name: {
      label: "Account Name",
      _id: "account_name",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "account_name",
      handleBlur: handleBlur,
      touched: touched["account_name"],
      error: errors["account_name"],
      onChange: handleChange,
      value: values["account_name"],
      required: true,
    },
    account_number: {
      label: "Account Number",
      _id: "account_number",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "account_number",
      handleBlur: handleBlur,
      touched: touched["account_number"],
      error: errors["account_number"],
      onChange: handleChange,
      value: values["account_number"],
      required: true,
    },
    bank_name: {
      label: "Bank Name",
      _id: "bank_name",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "bank_name",
      handleBlur: handleBlur,
      touched: touched["bank_name"],
      error: errors["bank_name"],
      onChange: handleChange,
      value: values["bank_name"],
      required: true,
    },
    bank_code: {
      label: "Bank Code",
      _id: "bank_code",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "bank_code",
      handleBlur: handleBlur,
      touched: touched["bank_code"],
      error: errors["bank_code"],
      onChange: handleChange,
      value: values["bank_code"],
      required: true,
    },
    branch_name: {
      label: "Branch Name",
      _id: "branch_name",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "branch_name",
      handleBlur: handleBlur,
      touched: touched["branch_name"],
      error: errors["branch_name"],
      onChange: handleChange,
      value: values["branch_name"],
      required: true,
    },
    branch_code: {
      label: "Branch Code",
      _id: "branch_code",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "branch_code",
      handleBlur: handleBlur,
      touched: touched["branch_code"],
      error: errors["branch_code"],
      onChange: handleChange,
      value: values["branch_code"],
      required: true,
    },
    swift_code: {
      label: "Swift Code (if any)",
      _id: "swift_code",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "swift_code",
      handleBlur: handleBlur,
      touched: touched["swift_code"],
      error: errors["swift_code"],
      onChange: handleChange,
      value: values["swift_code"],
      // required: true,  // Not Compulsory
    },
    contact_person: {
      label: "Contact Person",
      _id: "contact_person",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "contact_person",
      handleBlur: handleBlur,
      touched: touched["contact_person"],
      error: errors["contact_person"],
      onChange: handleChange,
      value: values["contact_person"],
      required: true,
    },
    address: {
      label: "Address",
      _id: "address",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "address",
      handleBlur: handleBlur,
      touched: touched["address"],
      error: errors["address"],
      onChange: handleChange,
      value: values["address"],
      required: true,
    },
    telephone_no: {
      label: "Telephone No.",
      _id: "telephone_no",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "telephone_no",
      handleBlur: handleBlur,
      touched: touched["telephone_no"],
      error: errors["telephone_no"],
      onChange: handleChange,
      value: values["telephone_no"],
      // required: true, // Not Compulsory
    },
    mobile_no: {
      label: "Mobile No.",
      _id: "mobile_no",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "mobile_no",
      handleBlur: handleBlur,
      touched: touched["mobile_no"],
      error: errors["mobile_no"],
      onChange: handleChange,
      value: values["mobile_no"],
      // required: true, // Not Compulsory
    },
    email: {
      label: "Email",
      _id: "email",
      type: "email",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "email",
      handleBlur: handleBlur,
      touched: touched["email"],
      error: errors["email"],
      onChange: handleChange,
      value: values["email"],
      required: true,
    },
    name_of_the_event: {
      label: "Name of the Event",
      _id: "name_of_the_event",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "name_of_the_event",
      handleBlur: handleBlur,
      touched: touched["name_of_the_event"],
      error: errors["name_of_the_event"],
      onChange: handleChange,
      value: values["name_of_the_event"],
      // required: true, // Not Compulsory
    },
    venue_name: {
      label: "Venue Name",
      _id: "venue_name",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "venue_name",
      handleBlur: handleBlur,
      touched: touched["venue_name"],
      error: errors["venue_name"],
      onChange: handleChange,
      value: values["venue_name"],
      // required: true, // Not Compulsory
    },
    date: {
      label: "Start Date",
      _id: "date",
      type: "date",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "date",
      handleBlur: handleBlur,
      touched: touched["date"],
      error: errors["date"],
      onChange: handleChange,
      value: values["date"],
      // required: true,
    },
    end_date: {
      label: "End Date",
      _id: "end_date",
      type: "date",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "end_date",
      handleBlur: handleBlur,
      touched: touched["end_date"],
      error: errors["end_date"],
      onChange: handleChange,
      value: values["end_date"],
    },
    time: {
      label: "Time",
      _id: "time",
      type: "time",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "time",
      handleBlur: handleBlur,
      touched: touched["time"],
      error: errors["time"],
      onChange: handleChange,
      value: values["time"],
      // required: true,
    },
    event_type: {
      exhibition: {
        label: "Exhibition",
        _id: "exhibition",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "exhibition",
      },
      conference: {
        label: "Conference",
        _id: "conference",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "conference",
      },
      convocation: {
        label: "Convocation",
        _id: "convocation",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "convocation",
      },
      seminar: {
        label: "Seminar",
        _id: "seminar",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "seminar",
      },
      ceremony: {
        label: "Ceremony",
        _id: "ceremony",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "ceremony",
      },
      other: {
        label: "Other",
        _id: "other",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "event_type",
        handleBlur: handleBlur,
        touched: touched["event_type"],
        error: errors["event_type"],
        onChange: handleChange,
        value: "other",
        other: {
          label: "",
          _id: "other",
          type: "text",
          parentClasses: margin_bottom,
          inputClass: "",
          labelClass: "",
          name: "other",
          handleBlur: handleBlur,
          touched: touched["other"],
          error: errors["other"],
          onChange: handleChange,
          value: values["other"],
          placeholder: "Please specify",
        },
      },
    },
    setup: {
      theatre: {
        label: "Theatre",
        _id: "theatre",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "theatre",
      },
      classroom: {
        label: "Classroom",
        _id: "classroom",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "classroom",
      },
      boardroom: {
        label: "Boardroom",
        _id: "boardroom",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "boardroom",
      },
      cluster: {
        label: "Cluster",
        _id: "cluster",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "cluster",
      },
      U_shape: {
        label: "U-Shape",
        _id: "U_shape",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "U_shape",
      },
      hollow: {
        label: "Hollow",
        _id: "hollow",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "hollow",
      },
      banquet: {
        label: "Banquet",
        _id: "banquet",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "banquet",
      },
      cocktail: {
        label: "Cocktail",
        _id: "cocktail",
        type: "checkbox",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "setup",
        handleBlur: handleBlur,
        touched: touched["setup"],
        error: errors["setup"],
        onChange: handleChange,
        value: "cocktail",
      },
    },
    public_performance_approval: {
      yes: {
        label: "Yes",
        _id: "public_performance_approval_yes",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "public_performance_approval",
        handleBlur: handleBlur,
        touched: touched["public_performance_approval"],
        error: errors["public_performance_approval"],
        onChange: handleChange,
        value: true,
      },
      no: {
        label: "No",
        _id: "public_performance_approval_no",
        parentClasses: margin_bottom,
        inputClass: "form-check-input",
        labelClass: "",
        name: "public_performance_approval",
        handleBlur: handleBlur,
        touched: touched["public_performance_approval"],
        error: errors["public_performance_approval"],
        onChange: handleChange,
        value: false,
      },
    },
    chief_guest: {
      label: "Chief Guest",
      _id: "chief_guest",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "chief_guest",
      handleBlur: handleBlur,
      touched: touched["chief_guest"],
      error: errors["chief_guest"],
      onChange: handleChange,
      value: values["chief_guest"],
    },
    number_of_guests: {
      expected: {
        label: "Expected",
        _id: "expected",
        type: "number",
        parentClasses: margin_bottom,
        inputClass: "",
        labelClass: "",
        name: "expected",
        handleBlur: handleBlur,
        touched: touched["expected"],
        error: errors["expected"],
        onChange: handleChange,
        value: values["expected"],
        required: true,
      },
      guaranteed: {
        label: "Guaranteed",
        _id: "guaranteed",
        type: "number",
        parentClasses: margin_bottom,
        inputClass: "",
        labelClass: "",
        name: "guaranteed",
        handleBlur: handleBlur,
        touched: touched["guaranteed"],
        error: errors["guaranteed"],
        onChange: handleChange,
        value: values["guaranteed"],
        required: true,
      },
    },
    VAT_registration_no: {
      label: "VAT Registration No.",
      _id: "VAT_registration_no",
      type: "text",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "VAT_registration_no",
      handleBlur: handleBlur,
      touched: touched["VAT_registration_no"],
      error: errors["VAT_registration_no"],
      onChange: handleChange,
      value: values["VAT_registration_no"],
      required: vatCheck,
    },
    VAT_copy: {
      label: "VAT Registration Copy",
      _id: "VAT_copy",
      type: "file",
      parentClasses: margin_bottom,
      inputClass: "",
      labelClass: "",
      name: "VAT_copy",
      handleBlur: handleBlur,
      touched: touched["VAT_copy"],
      error: errors["VAT_copy"],
      onChange: handleChange,
      value: values["VAT_copy"],
      required: vatCheck,
    },
  };

  const [meal, setMeal] = useState({
    breakfast: {
      check: false,
      time: "",
    },
    morning_snack: {
      check: false,
      time: "",
    },
    morning_snack_and_lunch: {
      check: false,
      time: "",
    },
    morning_snack_lunch_and_evening_snack: {
      check: false,
      time: "",
    },
    lunch_and_Evening_snack: {
      check: false,
      time: "",
    },
    evening_snack: {
      check: false,
      time: "",
    },
    lunch: {
      check: false,
      time: "",
    },
    dinner: {
      check: false,
      time: "",
    },
    cocktail: {
      check: false,
      time: "",
    },
  });

  useEffect(() => {
    // Load the previous reservation count from local storage or an API when the component mounts
    const previousCount = localStorage.getItem("reservation_Count");
    if (previousCount) {
      setReservationCount(parseInt(previousCount));
    }
  }, []);

  const generateNextBookingId = (previousId) => {
    // Split the previousId into parts
    const parts = previousId.split("-");

    // Extract the numeric part and increment it
    let numericPart = parseInt(parts[2].substring(4)); // Extract numeric part and parse as integer
    if (isNaN(numericPart)) numericPart = 950; // If the numeric part is not a number, set it to 0
    numericPart++; // Increment by 1

    // Generate the next ID
    const nextId = `${parts[0]}-${parts[1]}-${parts[2].substring(
      0,
      4
    )}${numericPart.toString().padStart(3, "0")}`;

    return nextId;
  };

  // Get the last booking number from the database
  const getLastBookingNumber = async () => {
    try {
      const res = await instance.get("/customer/last-booking-number");
      // console.log("This is the response", res.data);
      if (res.data.length === 0) {
        console.log("No previous booking number found");
        const previousId = `EVN-${new Date().getFullYear()}-0000`;
        // console.log("Previous ID", previousId);
        const nextId = generateNextBookingId(previousId);
        console.log("Next ID", nextId);
        setBookingNumber(nextId);
      } else {
        const previousId = res.data[0].booking_id;
        const nextId = generateNextBookingId(previousId);
        setBookingNumber(nextId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  useEffect(() => {
    getLastBookingNumber();
  }, []);

  return (
    <div className="col-md-12 order-md-1">
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reservation Request
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to submit this request?
            </Typography>

            <div className="mt-4 d-flex justify-content-around">
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#c17603",
                  border: "1px solid #c17603",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  submit();
                }}
                style={{ backgroundColor: "#c17603", color: "white" }}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <form className="needs-validation" onSubmit={submit}>
        <div className="row">
          <TextInput {...fields.name_of_organization_individual} />
        </div>

        <p className="fw-bold">Refund cheque to be drawn in favour of :</p>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.account_name} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.account_number} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.bank_name} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.bank_code} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.branch_name} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.branch_code} />
          </div>
        </div>

        <div className="row">
          <TextInput {...fields.swift_code} />
        </div>

        <p className="fw-bold">Contact Details :</p>

        <div className="row">
          <TextInput {...fields.contact_person} />
        </div>

        <div className="row">
          <TextInput {...fields.address} />
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.telephone_no} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.mobile_no} />
          </div>
        </div>

        <div className="row">
          <TextInput {...fields.email} />
        </div>

        <hr className="mb-4" />

        <p className="fw-bold">
          Name to be on video wall & LED screen: as follows (Once given,
          cannot be changed)
        </p>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.name_of_the_event} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.venue_name} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.date} />
          </div>
          <div className="col-md-6">
        <TextInput {...fields.end_date} />
      </div>
          {/* <div className="col-md-6">
            <TextInput {...fields.time} />
          </div> */}
        </div>

        <hr />

        <p className="fw-bold">
          Event Type <span className="require">*</span>
        </p>

        <div className="row">
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.event_type.exhibition} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.event_type.conference} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.event_type.convocation} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.event_type.seminar} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.event_type.ceremony} />
          </div>
        </div>

        <Checkbox {...fields.event_type.other} />
        {values.event_type.includes("other") && (
          <div className="row mt-2">
            <TextInput {...fields.event_type.other.other} />
          </div>
        )}

        {errors && errors.event_type && (
          <p style={{ color: "red" }}>{errors.event_type}</p>
        )}

        <hr />

        <p className="fw-bold">Set-Up</p>

        <div className="row">
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.theatre} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.classroom} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.boardroom} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.cluster} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.U_shape} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.hollow} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.banquet} />
          </div>
          <div className="col-md-4 mb-2">
            <Checkbox {...fields.setup.cocktail} />
          </div>
        </div>

        <hr />

        <p className="fw-bold">Meal Plan</p>
        <table className="table">
          <tbody>
            <tr>
              <td>Breakfast</td>
              <td>
                <input
                  type="checkbox"
                  name="breakfast_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      breakfast: {
                        ...prv.breakfast,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.breakfast.check && (
                  <input
                    type="time"
                    required
                    id="breakfast_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        breakfast: { ...prv.breakfast, time: e.target.value },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Morning Snack</td>
              <td>
                <input
                  type="checkbox"
                  name="morning_snack_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      morning_snack: {
                        ...prv.morning_snack,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.morning_snack.check && (
                  <input
                    type="time"
                    id="morning_snack_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        morning_snack: {
                          ...prv.morning_snack,
                          time: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Morning Snack & Lunch</td>
              <td>
                <input
                  type="checkbox"
                  name="morning_snack_and_lunch_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      morning_snack_and_lunch: {
                        ...prv.morning_snack_and_lunch,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.morning_snack_and_lunch.check && (
                  <input
                    type="time"
                    id="morning_snack_and_lunch_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        morning_snack_and_lunch: {
                          ...prv.morning_snack_and_lunch,
                          time: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Morning Snack, Lunch & Evening Snack</td>
              <td>
                <input
                  type="checkbox"
                  name="morning_snack_lunch_and_evening_snack_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      morning_snack_lunch_and_evening_snack: {
                        ...prv.morning_snack_lunch_and_evening_snack,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.morning_snack_lunch_and_evening_snack.check && (
                  <input
                    type="time"
                    id="morning_snack_lunch_and_evening_snack_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        morning_snack_lunch_and_evening_snack: {
                          ...prv.morning_snack_lunch_and_evening_snack,
                          time: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Lunch & Evening Snack</td>
              <td>
                <input
                  type="checkbox"
                  name="lunch_and_Evening_snack_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      lunch_and_Evening_snack: {
                        ...prv.lunch_and_Evening_snack,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.lunch_and_Evening_snack.check && (
                  <input
                    type="time"
                    id="lunch_and_Evening_snack_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        lunch_and_Evening_snack: {
                          ...prv.lunch_and_Evening_snack,
                          time: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Evening Snack</td>
              <td>
                <input
                  type="checkbox"
                  name="evening_snack_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      evening_snack: {
                        ...prv.evening_snack,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.evening_snack.check && (
                  <input
                    type="time"
                    id="evening_snack_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        evening_snack: {
                          ...prv.evening_snack,
                          time: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Lunch</td>
              <td>
                <input
                  type="checkbox"
                  name="lunch_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      lunch: {
                        ...prv.lunch,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.lunch.check && (
                  <input
                    type="time"
                    id="lunch_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        lunch: { ...prv.lunch, time: e.target.value },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Dinner</td>
              <td>
                <input
                  type="checkbox"
                  name="dinner_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      dinner: {
                        ...prv.dinner,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.dinner.check && (
                  <input
                    type="time"
                    id="dinner_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        dinner: { ...prv.dinner, time: e.target.value },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Cocktail</td>
              <td>
                <input
                  type="checkbox"
                  name="cocktail_check_box"
                  onChange={(e) =>
                    setMeal((prv) => ({
                      ...prv,
                      cocktail: {
                        ...prv.cocktail,
                        check: e.target.checked,
                      },
                    }))
                  }
                />
              </td>
              <td>
                {meal.cocktail.check && (
                  <input
                    type="time"
                    id="cocktail_time"
                    onChange={(e) =>
                      setMeal((prv) => ({
                        ...prv,
                        cocktail: { ...prv.cocktail, time: e.target.value },
                      }))
                    }
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <p className="fw-bold mb-2">
          Public Performance approval <span className="require">*</span>
        </p>

        <div className="row">
          <div className="col-md-2 mb-2">
            <Radio {...fields.public_performance_approval.yes} />
          </div>
          <div className="col-md-2 mb-2">
            <Radio {...fields.public_performance_approval.no} />
          </div>
        </div>
        {errors && errors.public_performance_approval && (
          <p style={{ color: "red" }}>{errors.public_performance_approval}</p>
        )}

        <hr />

        <p className="fw-bold">Chief Guest</p>
        <div className="row">
          <TextInput {...fields.chief_guest} />
        </div>

        <hr />

        <p className="fw-bold">Number of Guests </p>

        <div className="row">
          <div className="col-md-6">
            <TextInput {...fields.number_of_guests.expected} />
          </div>
          <div className="col-md-6">
            <TextInput {...fields.number_of_guests.guaranteed} />
          </div>
        </div>

        <hr />

        <div className="fw-bold mb-2">
          Are you VAT verified? <span className="require">*</span>
        </div>

        <div className="row">
          <div className="col-md-2 mb-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="vatVerifiedYes"
                name="vatVerified"
                onChange={handleVatCheck}
                value="true"
                checked={vatVerified === true}
              />
              <label className="form-check-label" htmlFor="vatVerifiedYes">
                Yes
              </label>
            </div>
          </div>
          <div className="col-md-2 mb-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="vatVerifiedNo"
                onChange={handleVatCheck}
                name="vatVerified"
                value="false"
                checked={vatVerified === false}
              />
              <label className="form-check-label" htmlFor="vatVerifiedNo">
                No
              </label>
            </div>
          </div>
        </div>

        {
          // Show VAT Registration Number and VAT Copy only if the user is VAT registered
          vatCheck && (
            <>
              <p className="fw-bold">VAT Registration</p>
              <div className="row">
                <TextInput {...fields.VAT_registration_no} />
              </div>

              <FileInput {...fields.VAT_copy} setVatImage={setVatImage} />
            </>
          )
        }

        <br />

        <PricingMoreDetails
          bookingData={props.bookingData}
          setPopup={props.setPopup}
          needArrAndDis={props.needArrAndDis}
          arrangementCost={props.arrangementCost}
          dismantlingCost={props.dismantlingCost}
        />

        <button className="reg-btn" type="submit">
          Submit Request
        </button>
      </form>
    </div>
  );
};

export default MainBookingForm;
