import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./login.scss";
import Image from "../../assets/img/bmich_login_image.png";
import Logo from "../../assets/img/Logo.png";
import TextInput from "../../components/form_component/TextInput";
import { LoginFormValidation } from "../../validation/FormValidation";
import SnackbarAlert from "../../components/alert/Snackbar";
import { instance } from "../../config/instance";
import Copyright from "../../components/cpyright.jsx/Copyright";

const Login = () => {
  const navigation = useNavigate();
  const [err, setErr] = useState({ open: false, msg: "" });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionID, setSessionID] = useState(null);

  useEffect(() => {
    const { Checkout } = window;

    if (!Checkout) return;

    async function initializeCheckoutSession() {
      try {
        const response = await instance.post("/customer/payment/session", {
          orderDescription: "Testing order",
          amount: "300",
        });

        const { sessionID, ok, message } = response.data;

        // console.log("This is session id : ", sessionID);
        if (sessionID) setSessionID(sessionID);

        if (!ok) return alert(message);

        Checkout.configure({
          session: {
            id: sessionID,
          },
        });
      } catch (error) {
        console.log("Error occured in initializeCheckout ", error);
        console.error("Thsis is error = ", error);
      }
    }

    initializeCheckoutSession();
  }, []);

  const login = async (values) => {
    setErr({ open: false, msg: "" });
    setLoading(true);
    try {
      const data = {
        email: values.email,
        password: values.password,
      };

      const res = await instance.post("/customer/login", data);
      if (res.status === 200) {
        navigation("/?login=success");
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (
        err.response.status === 400 &&
        err.response.data.message === "Invalid email or password"
      ) {
        setErr({ open: true, msg: "Login failed" });
        setFieldError("email", "Invalid email or password");
        setLoading(false);
        return;
      }
      setErr({ open: true, msg: "Login failed" });
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => login(values),
    validationSchema: LoginFormValidation,
  });

  

  useEffect(() => {
    setSuccess(false);
    const url = window.location.href;
    if (url.includes("registered=success")) {
      setSuccess(true);
    }
  }, []);

  const fields = [
    {
      _id: 1,
      label: "Email",
      type: "email",
      name: "email",
      value: values.email,
      error: errors.email,
      touched: touched.email,
      required: true,
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      _id: 2,
      label: "Password",
      type: "password",
      name: "password",
      value: values.password,
      error: errors.password,
      touched: touched.password,
      required: true,
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
  return (
    <div id="login">
      {err.open && (
        <SnackbarAlert msg={err.msg} type="error" variant="filled" />
      )}
      {success && (
        <SnackbarAlert
          msg="Registration Success"
          type="success"
          variant="filled"
        />
      )}
      <div className="book-venue-btn">
        <button onClick={() => navigation("/")}>Book a Venue</button>
      </div>
      <div className="card-container">
        <div className="left column">
          <img src={Image} alt="image" />
        </div>
        <div className="right column">
          <div className="head">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
            <p>LOGIN TO YOUR ACCOUNT</p>
          </div>

          <div className="form-container">
            <form onSubmit={handleSubmit}>
              {fields.map((field, index) => {
                return (
                  <div style={{ width: "100%" }} key={index}>
                    <label htmlFor={field.name} className="col-3  mb-1">
                      {field.label} <span className="require">*</span>
                    </label>
                    <input
                      type={field.type}
                      className={`form-control ${
                        field.error && field.touched
                          ? "is-invalid"
                          : "custom-input"
                      }`}
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                    <div>
                      {field.touched && field.error ? (
                        <p style={{ color: "red" }}>{field.error}</p>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </div>
                  </div>
                );
              })}
              <Link to="/forgot-password" className="for-passwd">
                Forgot your password?
              </Link>
              {loading ? (
                <button class="submit-button" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm mx-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Login...</span>
                </button>
              ) : (
                <button type="submit" className="submit-button">
                  Login
                </button>
              )}
              <Link to="/register" className="for-sign-up-link">
                Don't have an account? <span>Register here</span>
              </Link>
            </form>
          </div>
        </div>
      </div>
      
      <Copyright />
      <div id="embed-target"></div>
    </div>
  );
};

export default Login;
