import * as Yup from 'yup';

export const LoginFormValidation = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
})

export const RegisterFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters')
        .max(20, 'Name must be at most 20 characters'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .max(20, 'Password must be at most 20 characters')
        .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}/, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')

})

export const PasswordUpdateFormValidation = Yup.object().shape({
    password: Yup.string()
        .required('New password is required')
        .min(6, 'Password must be at least 6 characters')
        .max(20, 'Password must be at most 20 characters')
        .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}/, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export const WelcomePageFormValidation = Yup.object().shape({
    start_date: Yup.date()
        .required('Start date is required')
        .min(new Date(), 'Start date must be after today'),
    end_date: Yup.date()
        .min(Yup.ref('start_date'), 'End date must be after start date'),
    venue_type: Yup.string()
        .required('Venue type is required'),

})

export const VenueOptionQtyFormValidation = Yup.object().shape({
    max_qty: Yup.number()
        .required('Maximum quantity is required'),
    qty: Yup.number()
        .required('Quantity is required')
        .min(1, 'Quantity must be at least 1')
        .max(Yup.ref('max_qty'), `Quantity must be at most ${Yup.ref('max_qty')}`)
})

export const BookingFormValidation = Yup.object().shape({
    name_of_organization_individual: Yup.string()
        .required('Name of organization/individual is required'),

    account_name: Yup.string()
        .required('Account name is required'),

    account_number: Yup.string()
        .required('Account number is required'),

    bank_name: Yup.string()
        .required('Bank name is required'),

    bank_code: Yup.string()
        .required('Bank code is required'),

    branch_name: Yup.string()
        .required('Branch name is required'),

    branch_code: Yup.string()
        .required('Branch code is required'),

    swift_code: Yup.string()
        .matches(/^[A-Za-z]{4}[A-Za-z]{2}\w{2}(\w{3})?$/, 'Invalid swift code'),

    contact_person: Yup.string()
        .required('Contact person is required'),

    address: Yup.string()
        .required('Address is required'),

    telephone_no: Yup.string()
        .matches(/^[0]{1}[0-9]{9}$/, 'Invalid telephone number'),

    mobile_no: Yup.string()
        .matches(/^[0]{1}[7]{1}[01245678]{1}[0-9]{7}$/, 'Invalid mobile number'),

    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),

    name_of_the_event: Yup.string(),

    venue_name: Yup.string(),

    date: Yup.date(),

    end_date: Yup.date(),

    time: Yup.string(),

    event_type: Yup.array()
        .min(1, 'Event type is required')
        .required('Event type is required'),

    setup: Yup.array(),

    public_performance_approval: Yup.boolean(),


    meal_plan: Yup.array().of(
        Yup.object().shape({
            meal: Yup.string().required('Meal is required'),
            time: Yup.string().when('selected', {
                is: true,
                then: Yup.string().required('Time is required'),
            }),
            selected: Yup.boolean(),
        })
    ),

    chief_guest: Yup.string(),

    expected: Yup.number()
        .required('Expected number of guests is required')
        .min(1, 'Expected number of guests must be at least 1'),
    guaranteed: Yup.number()
        .required('Guaranteed number of guests is required')
        .min(0, 'Guaranteed number of guests must be at least 0'),

    VAT_registration_no: Yup.string()
        .matches(/^\d{9}$/, 'Invalid VAT registration'),

    VAT_copy: Yup.mixed(),

    other: Yup.string()
        


})