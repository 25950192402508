import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { BookingProvider } from './context/BookingContext'
import FloatingBox from './context/FloatingBox.jsx'


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    
    {/* <App />*/}
    <BookingProvider>
    <FloatingBox /> {/* FloatingBox will be displayed on every page */}
      <App />
    </BookingProvider>
  </React.StrictMode>,
)
