import React, { useEffect, useState } from "react";
import "./booking-form.scss";
import MainBookingForm from "./MainBookingForm";
import Pricing from "./Pricing";
import { useLocation } from "react-router-dom";
import PricingMoreDetails from "./PricingMoreDetails";

const FormAndPricing = () => {
  const location = useLocation();
  const [bookingData, setBookingData] = useState(null);
  const [venue, setVenue] = useState(null);
  const [needArrAndDis, setNeedArrAndDis] = useState(false);
  const [arrangementsDismantling, setArrangementsDismantling] = useState(null);
  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [arrangementCost, setArrangementCost] = useState(0);
  const [dismantlingCost, setDismantlingCost] = useState(0);

  useEffect(() => {
    const bookingData = location.state?.booking;
    const venue = location.state?.venue;
    const needArrAndDis = location.state?.needArrAndDis;
    const arrangementsDismantling = location.state?.arrangementsDismantling;
    const StartTime = location.state?.eventStartTime;
    const EndTime = location.state?.eventEndTime;

    setBookingData(bookingData);
    setVenue(venue);
    setNeedArrAndDis(needArrAndDis);
    setArrangementsDismantling(arrangementsDismantling);
    setEventStartTime(StartTime);
    setEventEndTime(EndTime);
  }, [location]);

  // Calculation for Arrangements and Dismantling Hours

  function convertTo24HourFormat(timeString) {
    let [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (hours === 12) {
      if (period === "am") {
        hours = 0;
      }
    } else if (period === "pm") {
      hours += 12;
    }

    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");

    return `${hours}:${minutes}:00`;
  }

  function convertEventStartDate(eventStartDate) {
    const eventDate = new Date(eventStartDate);
    const year = eventDate.getFullYear();
    const month = String(eventDate.getMonth() + 1).padStart(2, "0");
    const day = String(eventDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function calculateHours(startDate, startTime, endDate, endTime) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    console.log(
      "Parsed start components:",
      startYear,
      startMonth,
      startDay,
      startHour,
      startMinute
    );
    console.log(
      "Parsed end components:",
      endYear,
      endMonth,
      endDay,
      endHour,
      endMinute
    );

    const startDateObj = new Date(
      startYear,
      startMonth - 1,
      startDay,
      startHour,
      startMinute
    );
    const endDateObj = new Date(
      endYear,
      endMonth - 1,
      endDay,
      endHour,
      endMinute
    );

    // console.log("Start Date object:", startDateObj);
    // console.log("End Date object:", endDateObj);

    const difference = endDateObj.getTime() - startDateObj.getTime();
    // console.log("Time difference in milliseconds:", difference);

    const hours = difference / (1000 * 60 * 60);

    // console.log("Calculated hours:", hours);

    return hours;
  }

  useEffect(() => {
    if (
      !bookingData ||
      !venue ||
      !arrangementsDismantling ||
      !eventStartTime ||
      !eventEndTime
    ) {
      return;
    }

    // values for arrangement cost calculation
    const formattedEventDate = convertEventStartDate(bookingData[0].date);
    const arrangementStartTime = convertTo24HourFormat(
      arrangementsDismantling.arrangement.startTime
    );

    const eventStartDate = bookingData[0].date.toISOString().split("T")[0];
    const arrangementStartDate = arrangementsDismantling.arrangement.date;
    const startTime = eventStartTime;
    const hours = calculateHours(
      arrangementsDismantling.arrangement.date,
      arrangementStartTime,
      formattedEventDate,
      eventStartTime
    );

    // console.log(venue.basic);

    const dayArrangementCost = venue.basic.day_charges;
    const nightArrangementCost = venue.basic.night_charges;
    const freeArrangements = venue.basic.free_arrangement;

    // console.log("These are all the values that we have here : ");
    // console.log("Event Start Date : ", eventStartDate);
    // console.log("Arrangement Start Date : ", arrangementStartDate);
    // console.log("Event Start Time : ", startTime);
    // console.log("Arrangement Start Time", arrangementStartTime);
    // console.log("Day Arrangement Cost : ", dayArrangementCost);
    // console.log("Night Arrangement Cost : ", nightArrangementCost);
    // console.log("Free Arrangements : ", freeArrangements);

    // eventStartDate,
    // arrangementStartDate,
    // eventStartTime,
    // arrangementStartTime,
    // dayArrangementCost,
    // nightArrangementCost,
    // freeArrangements

    const arrangementCost = calculateArrangementCost(
      eventStartDate,
      arrangementStartDate,
      startTime,
      arrangementStartTime,
      parseInt(dayArrangementCost),
      parseInt(nightArrangementCost),
      freeArrangements
    );

    // eventEndDate,
    // eventEndTime,
    // DismantingDate,
    // DismantlingEndTime,
    // hours,
    // dayDismantlingCost,
    // nightDismantlingCost,
    // freeDismantling

    // console.log("Arrangement Cost : ", arrangementCost);

    if (arrangementCost) {
      setArrangementCost(arrangementCost);
    }

    // values for dismantling cost calculation
    const eventEndDate = bookingData[bookingData.length - 1].date
      .toISOString()
      .split("T")[0];
    const endTime = eventEndTime;
    const DismantingDate = arrangementsDismantling.dismantling.date;
    const DismantlingEndTime = convertTo24HourFormat(
      arrangementsDismantling.dismantling.endTime
    );
    const dayDismantlingCost = venue.basic.day_charges;
    const nightDismantlingCost = venue.basic.night_charges;
    const freeDismantling = venue.basic.free_dismantling;

    // console.log("These are all the values that we have here : ");
    // console.log("Event End Date : ", eventEndDate);
    // console.log("Event End Time : ", endTime);
    // console.log("Dismanting Date : ", DismantingDate);
    // console.log("Dismantling End Time : ", DismantlingEndTime);
    // console.log("Day Dismantling Cost : ", dayDismantlingCost);
    // console.log("Night Dismantling Cost : ", nightDismantlingCost);
    // console.log("Free Dismantling : ", freeDismantling);

    const dismantlingCost = calculateDismantlingCost(
      eventEndDate,
      endTime,
      DismantingDate,
      DismantlingEndTime,
      hours,
      parseInt(dayDismantlingCost),
      parseInt(nightDismantlingCost),
      freeDismantling
    );

    // console.log("Dismantling Cost : ", dismantlingCost);

    if (dismantlingCost) {
      setDismantlingCost(dismantlingCost);
    }
  }, [
    bookingData,
    venue,
    arrangementsDismantling,
    eventStartTime,
    eventEndTime,
  ]);

  if (!bookingData || !venue) {
    return (
      <>
        <div id="margin"></div>
        <p>Loading...</p>
      </>
    );
  }

  // get first and last day
  const firstDay = new Date(bookingData[0].date).toLocaleDateString("en-IN", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const lastDay = new Date(
    bookingData[bookingData.length - 1].date
  ).toLocaleDateString("en-IN", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedEventDate = convertEventStartDate(bookingData[0].date);

  function calculateTimeDifference(eventStartTime) {
    // Split the event start time into hours and minutes
    const [eventHours, eventMinutes] = eventStartTime.split(":").map(Number);

    // Ensure event time is after 12:00:00 on the same day (considering edge case)
    if (eventHours < 12) {
      return 0; // No difference if event starts before 12 PM
    }

    // Calculate difference in hours (assuming 6:00:00 as fixed time)
    const difference = eventHours - 6;

    return difference;
  }

  function calculateTimeDifferenceInHours(time1, time2) {
    // Split the time strings into hours, minutes, and seconds
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

    // Convert hours and minutes to minutes for easier calculation
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the time difference in minutes
    const differenceInMinutes = Math.abs(totalMinutes2 - totalMinutes1);

    // Convert the time difference to hours
    const differenceInHours = differenceInMinutes / 60;

    return differenceInHours;
  }

  function calculateArrangementCost(
    eventStartDate,
    arrangementStartDate,
    eventStartTime,
    arrangementStartTime,
    dayArrangementCost,
    nightArrangementCost,
    freeArrangements
  ) {
    let cost = 0;

    if (eventStartDate !== arrangementStartDate) {
      // console.log("Event and Arrangement Date is Different");
      // Calculate The Days Difference
      const eventDate = new Date(eventStartDate);
      const arrangementDate = new Date(arrangementStartDate);
      const timeDifferenceInMilliseconds = Math.abs(
        eventDate - arrangementDate
      );

      const dayDifference = Math.ceil(
        timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
      );

      if (dayDifference === 1) {
        if (freeArrangements) {
          // console.log("Free Arrangements");
          if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime >= "20:00:00" &&
            arrangementStartTime < "24:00:00"
          ) {
            // Event Start Time is before 12:00:00
            cost = nightArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "20:00:00" &&
            arrangementStartTime >= "08:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "08:00:00"
          ) {
            cost +=
              nightArrangementCost + dayArrangementCost + nightArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime >= "20:00:00" &&
            arrangementStartTime < "24:00:00"
          ) {
            cost += nightArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime >= "08:00:00" &&
            arrangementStartTime < "20:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime < "08:00:00"
          ) {
            cost +=
              nightArrangementCost + dayArrangementCost + nightArrangementCost;
          } else {
            cost += nightArrangementCost + dayArrangementCost;
          }
        } else {
          if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime > "20:00:00"
          ) {
            // Event Start Time is before 12:00:00
            cost = nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "20:00:00" &&
            arrangementStartTime > "08:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime > "20:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime < "20:00:00"
          ) {
            cost +=
              nightArrangementCost + dayArrangementCost + dayArrangementCost;
          } else {
            cost += nightArrangementCost + dayArrangementCost;
          }
        }
      } else if (dayDifference > 1) {
        if (freeArrangements) {
          // console.log("Free Arrangements");
          // console.log("Day Difference: ", dayDifference);

          // New One
          if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime >= "20:00:00" &&
            arrangementStartTime < "24:00:00"
          ) {
            // Event Start Time is before 12:00:00
            cost = nightArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "20:00:00" &&
            arrangementStartTime >= "08:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "08:00:00"
          ) {
            cost +=
              nightArrangementCost + dayArrangementCost + nightArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime > "20:00:00"
          ) {
            cost += nightArrangementCost;
          } else {
            cost += nightArrangementCost + dayArrangementCost;
          }

          // Calculate the cost for the days in between excuding the first and last day
          for (let i = 1; i <= dayDifference - 1; i++) {
            cost += dayArrangementCost + nightArrangementCost;
            // console.log("mein hoon");
          }
        } else {
          if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime > "20:00:00"
          ) {
            // Event Start Time is before 12:00:00
            cost = nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime <= "12:00:00" &&
            arrangementStartTime < "20:00:00" &&
            arrangementStartTime > "08:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime > "20:00:00"
          ) {
            cost += nightArrangementCost + dayArrangementCost;
          } else if (
            eventStartTime > "12:00:00" &&
            arrangementStartTime < "20:00:00"
          ) {
            cost +=
              nightArrangementCost + dayArrangementCost + dayArrangementCost;
          } else {
            cost += nightArrangementCost + dayArrangementCost;
          }

          // Calculate the cost for the days in between excuding the first and last day
          for (let i = 1; i < dayDifference - 1; i++) {
            cost += dayArrangementCost + nightArrangementCost;
          }
        }
      }
    } else {
      // console.log("Event and Arrangement Date is Same");
      if (freeArrangements) {
        // console.log("Free Arrangements");
        // Check if event is starting before 12:00 pm
        if (eventStartTime <= "12:00:00") {
          // console.log("1");
          cost = 0;
        } else if (
          eventStartTime > "12:00:00" &&
          arrangementStartTime <= "06:00:00"
        ) {
          // console.log("2");
          cost += nightArrangementCost;
        } else {
          // console.log("3");
          cost = 0;
        }
      } else {
        if (eventStartTime <= "08:00:00") {
          cost += nightArrangementCost;
        } else if (
          arrangementStartTime <= "08:00:00" &&
          eventStartTime > "12:00:00"
        ) {
          cost += nightArrangementCost + dayArrangementCost;
        } else {
          cost += dayArrangementCost;
        }
      }
    }

    return cost;
  }

  function calculateDismantlingCost(
    eventEndDate,
    eventEndTime,
    DismantingDate,
    DismantlingEndTime,
    dayDismantlingCost,
    nightDismantlingCost,
    freeDismantling
  ) {
    let dismantleCost = 0;

    if (eventEndDate !== DismantingDate) {
      // console.log("Event and Dismantling Date is Different");
      // Calculate The Days Difference
      const eventEnd = new Date(eventEndDate);
      const dismantling = new Date(DismantingDate);
      const timeDifferenceInMillisecondsDismantling = Math.abs(
        eventEnd - dismantling
      );

      const dayDifferenceDismantling = Math.ceil(
        timeDifferenceInMillisecondsDismantling / (1000 * 60 * 60 * 24)
      );

      if (dayDifferenceDismantling === 1) {
        if (freeDismantling) {
          // Date Difference = 1

          // Calculate Charges for Event End Date and Time

          if (eventEndTime >= "00:00:00" && eventEndTime < "08:00:00") {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost + dayDismantlingCost; // 300
            console.log("Idhr aya hua");
          } else if (eventEndTime >= "08:00:00" && eventEndTime < "16:00:00") {
            // 8:AM - 5:PM
            dismantleCost += dayDismantlingCost;
          }

          // Calculate Charges for Dismantling Date and Time

          if (
            DismantlingEndTime >= "00:00:00" &&
            DismantlingEndTime <= "08:00:00"
          ) {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost;
          } else if (
            DismantlingEndTime > "08:00:00" &&
            DismantlingEndTime <= "18:00:00"
          ) {
            // 8:AM - 5:PM
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }
        } else {
          // Date Difference = 1  // DISMANTLING CORRECT CALCULATION
          if (eventEndTime >= "00:00:00" && eventEndTime < "08:00:00") {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost + dayDismantlingCost; // 300
            console.log("Idhr aya hua");
          } else if (eventEndTime >= "08:00:00" && eventEndTime < "20:00:00") {
            // 8:AM - 8:PM
            dismantleCost += dayDismantlingCost;
          }

          // Calculate Charges for Dismantling Date and Time

          if (
            DismantlingEndTime >= "00:00:00" &&
            DismantlingEndTime <= "08:00:00" &&
            eventEndTime >= "20:00:00"
          ) {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost;
          } else if (
            DismantlingEndTime > "08:00:00" &&
            DismantlingEndTime <= "20:00:00"
          ) {
            // 8:AM - 5:PM
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }
        }
      } else {
        if (freeDismantling) {
          // console.log("Free Dismantling");
          // console.log("Day Difference: ", dayDifferenceDismantling);

          // Calculate Charges for Event End Date and Time

          if (eventEndTime >= "00:00:00" && eventEndTime < "08:00:00") {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost + dayDismantlingCost; // 300
          } else if (eventEndTime >= "08:00:00" && eventEndTime < "16:00:00") {
            // 8:AM - 5:PM
            dismantleCost += dayDismantlingCost;
          }

          // Calculate Charges for Dismantling Date and Time
          if (
            DismantlingEndTime >= "00:00:00" &&
            DismantlingEndTime <= "08:00:00"
          ) {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost;
          } else if (
            DismantlingEndTime > "08:00:00" &&
            DismantlingEndTime <= "18:00:00"
          ) {
            // 8:AM - 5:PM
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }

          // Calculate the cost for the days in between excuding the first and last day
          for (let i = 1; i <= dayDifferenceDismantling - 1; i++) {
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }
        } else {
          if (eventEndTime >= "00:00:00" && eventEndTime < "08:00:00") {
            // 12:AM - 8:AM
            dismantleCost += nightDismantlingCost + dayDismantlingCost;
          } else if (eventEndTime >= "08:00:00" && eventEndTime < "20:00:00") {
            // 8:AM - 8:PM
            dismantleCost += dayDismantlingCost;
          }

          // Calculate Charges for Dismantling Date and Time

          if (
            DismantlingEndTime >= "00:00:00" &&
            DismantlingEndTime <= "08:00:00" &&
            eventEndTime >= "20:00:00"
          ) {
            // 12:AM - 8:AM
            // console.log("Thoko");
            dismantleCost += nightDismantlingCost;
          } else if (
            DismantlingEndTime > "08:00:00" &&
            DismantlingEndTime <= "20:00:00" &&
            eventEndTime >= "20:00:00"
          ) {
            // 8:AM - 5:PM
            // console.log("Teri ma marta hoon sab le");
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }

          // Calculate the cost for the days in between excuding the first and last day
          for (let i = 1; i <= dayDifferenceDismantling - 1; i++) {
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          }
        }
      }
    } else {
      // Calculate DisCost Event End Date and Dismantaling Date Is Same
      if (freeDismantling) {
        const timeDifferenceInHours = calculateTimeDifferenceInHours(
          eventEndTime,
          DismantlingEndTime
        );
        if (timeDifferenceInHours > 4) {
          // Check if event is ending after 8:00 pm
          if (
            eventEndTime >= "00:00:00" &&
            eventEndTime < "04:00:00" &&
            DismantlingEndTime > "08:00:00"
          ) {
            dismantleCost += nightDismantlingCost + dayDismantlingCost;
          } else if (
            eventEndTime > "04:00:00" &&
            eventEndTime <= "08:00:00" &&
            DismantlingEndTime > "08:00:00"
          ) {
            dismantleCost += dayDismantlingCost; // Free Night Cost
          } else if (
            eventEndTime >= "06:00:00" &&
            eventEndTime < "12:00:00" &&
            DismantlingEndTime < "20:00:00"
          ) {
            dismantleCost += dayDismantlingCost;
          } else if (
            eventEndTime >= "12:00:00" &&
            eventEndTime < "16:00:00" &&
            DismantlingEndTime > "20:00:00"
          ) {
            dismantleCost += dayDismantlingCost + nightDismantlingCost;
          } else if (
            eventEndTime >= "16:00:00" &&
            eventEndTime <= "20:00:00" &&
            DismantlingEndTime > "20:00:00"
          ) {
            dismantleCost += nightDismantlingCost; // Free Day Cost
          }
        } else {
          dismantleCost = 0;
        }
      } else {
        // Check if event is ending after 8:00 pm
        if (
          eventEndTime >= "00:00:00" &&
          eventEndTime < "08:00:00" &&
          DismantlingEndTime > "08:00:00"
        ) {
          dismantleCost += nightDismantlingCost + dayDismantlingCost;
        } else if (
          eventEndTime >= "00:00:00" &&
          eventEndTime < "08:00:00" &&
          DismantlingEndTime <= "08:00:00"
        ) {
          dismantleCost += nightDismantlingCost;
        } else if (
          eventEndTime >= "08:00:00" &&
          eventEndTime < "20:00:00" &&
          DismantlingEndTime < "08:00:00"
        ) {
          dismantleCost += dayDismantlingCost;
        } else if (
          eventEndTime >= "16:00:00" &&
          eventEndTime <= "20:00:00" &&
          DismantlingEndTime > "20:00:00"
        ) {
          dismantleCost += nightDismantlingCost + dayDismantlingCost;
        }
      }
    }

    return dismantleCost;
  }

  bookingData.forEach((data) => {
    // console.log("This is the data that we have here : ", data);
    data.options.forEach((option) => {
      // console.log("This is the option that we have here : ", option);

      const selectedStartTime = new Date(option.time.start);
      const selectedEndTime = new Date(option.time.end);
      

      // Extracting start time in 24-hour format
      const startTimeHours = selectedStartTime.getHours();
      const startTimeMinutes = selectedStartTime.getMinutes();
      const startTimeFormatted = `${
        startTimeHours < 10 ? "0" : ""
      }${startTimeHours}:${
        startTimeMinutes < 10 ? "0" : ""
      }${startTimeMinutes}:00`;

      // Extracting end time in 24-hour format
      const endTimeHours = selectedEndTime.getHours();
      const endTimeMinutes = selectedEndTime.getMinutes();
      const endTimeFormatted = `${
        endTimeHours < 10 ? "0" : ""
      }${endTimeHours}:${endTimeMinutes < 10 ? "0" : ""}${endTimeMinutes}:00`;

      // console.log("Start Time : ", startTimeFormatted);
      // console.log("End Time : ", endTimeFormatted);

      
    });
  });

  return (
    <>
      <div id="margin"></div>
      <div id="form-and-pricing">
        <div className="container">
          <h2>
            {venue.basic.name}
            <span
              style={{
                fontSize: "25px",
                color: "grey",
                marginLeft: "10px",
              }}
            >
              ({firstDay} - {lastDay})
            </span>
          </h2>
          <br />
          <div className="row">
            {/* <Pricing bookingData={bookingData} /> */}
            <MainBookingForm
              bookingData={bookingData}
              venue={venue.basic}
              needArrAndDis={needArrAndDis}
              arrangementsDismantling={arrangementsDismantling}
              arrangementCost={arrangementCost}
              dismantlingCost={dismantlingCost}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAndPricing;
