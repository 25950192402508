import React, { useEffect, useState } from 'react'
import './layout.scss'
import Nav from '../components/nav-bar/Nav'
import { Outlet, useNavigate } from 'react-router-dom'
import useAuthStore from '../utility/store/AuthStore'
import { checkUserAuth } from '../utility/CommonFunc'
import { instance } from '../config/instance'
import Copyright from '../components/cpyright.jsx/Copyright'

const PublicLayout = () => {
  const navigation = useNavigate()
  const [loading, setLoading] = useState(null);
  const [activation, setActivation] = useState(null);
  const setAuth = useAuthStore(state => state.setAuth);
  const auth = useAuthStore(state => state.authState);

  useEffect(() => {

    const checkActivation = async (res) => {
      if (res) {
        const res2 = await instance.post('/customer/check-activation');
        setActivation(res2.data.activated)
      }
      else {
        setActivation(false)
      }
    }

    const setUserAuth = async () => {
      try {
        const res = await checkUserAuth();
        setAuth(res);
        setLoading(false);
        await checkActivation(res);
      }
      catch (err) {
        console.log(err);
        setActivation(false)
        setLoading(false);
        return false
      }
    }



    setUserAuth()


  }, [])

  if (loading == null || activation == null) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )

  }

  if (auth && !activation) {
    return (
      <div id='registrationSuccess' style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <p>Account not activated. Check your email and activate using activation link</p>
        <div>
          <button onClick={async () => {
            const res = await instance.get('/customer/logout');
            if (res.status === 200) {
              setAuth(false);
              navigation('/login')
            }
          }}
            style={{
              padding: '10px',
              backgroundColor: '#C17603',
              color: 'white',
              border: 'none',
              borderRadius: '5px'
            }}
          >Logout</button>
        </div>
      </div>
    )
  }

  return (
    <div id='public-layout'>
      <div className='nav-div'>
        <Nav />
      </div>

      <div className='outlet'>
        <Outlet />
      </div>
    </div>
  )
}

export default PublicLayout