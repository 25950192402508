// import React from 'react'
// import './search_event.scss'
// import { AiOutlineCaretDown } from "react-icons/ai";
// import { BiFilterAlt } from "react-icons/bi";
// import { useFormik } from 'formik';
// import { WelcomePageFormValidation } from '../../validation/FormValidation';
// import { venue_type } from '../../config/constance';
// import { useNavigate } from 'react-router-dom';

// const SearchEventCard = ({ open, setOpen, useSearch, setParam }) => {
//   const navigation = useNavigate()

//   const submit = ({ venue_type, start_date, end_date }) => {
//     console.log(end_date);
//     setParam({ venue_type, start_date, end_date })
//     navigation(`/venues/?test=123&venue_type=${venue_type}&start_date=${start_date}&end_date=${end_date}`)
//   }
//   // console.log(useSearch);
//   const { values, errors, handleChange, touched, handleBlur, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
//     initialValues: {
//       venue_type: useSearch.venue_type,
//       start_date: useSearch.start_date,
//       end_date: useSearch.end_date
//     },
//     onSubmit: (values) => submit(values),
//     validationSchema: WelcomePageFormValidation
//   })
//   return (
//     <div className="card" id='search-event-card'>
//       <div className="title toggle">
//         <div></div>
//         <h1>Book a Venue</h1>
//         <AiOutlineCaretDown className='icon toggle' />
//       </div>
//       <form className="inputs row" onSubmit={handleSubmit}>
//         <div className="col">
//           <label htmlFor="type-of-event">Type of Event</label>
//           <select
//             className="form-select"
//             aria-label="type-of-event"
//             id='type-of-event'
//             name='venue_type'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.venue_type}
//           >
//             <option value="all" > All </option>
//             {
//               venue_type.map((option, index) => {
//                 return (
//                   <option key={index} value={option.value}>{option.label}</option>
//                 )
//               })
//             }
//           </select>
//           <span>
//             {touched.venue_type && errors.venue_type ? <p style={{ color: 'red' }}>{errors.venue_type}</p> : <div style={{ height: '20px' }}></div>}
//           </span>
//         </div>
//         <div className="col">
//           <label htmlFor="endDate">Start Date</label>
//           <input
//             id="endDate"
//             className="form-control"
//             type="date"
//             name='start_date'
//             value={values.start_date}
//             onChange={handleChange}
//             onBlur={handleBlur}
//           />
//           <span>
//             {touched.start_date && errors.start_date ? <p style={{ color: 'red' }}>{errors.start_date}</p> : <div style={{ height: '20px' }}></div>}
//           </span>
//         </div>
//         <div className="col">
//           <label htmlFor="endDate">End Date</label>
//           <input
//             id="endDate"
//             className="form-control"
//             type="date"
//             name='end_date'
//             value={values.end_date}
//             onChange={handleChange}
//             onBlur={handleBlur}
//           />
//           <span>
//             {touched.end_date && errors.end_date ? <p style={{ color: 'red' }}>{errors.end_date}</p> : <div style={{ height: '20px' }}></div>}
//           </span>
//         </div>

//         <div className="col submit-col">
//           <label htmlFor="endDate">submit button</label>
//           <button type="submit" className="btn btn-primary">Submit</button>
//           {typeof (open) !== 'undefined' && !open && <BiFilterAlt className='icon' onClick={() => setOpen(true)} />}
//         </div>
//       </form>
//     </div>
//   )
// }

// export default SearchEventCard
import React from 'react';
import './search_event.scss';
import { AiOutlineCaretDown } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { useFormik } from 'formik';
import { WelcomePageFormValidation } from '../../validation/FormValidation';
import { venue_type } from '../../config/constance';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";


const SearchEventCard = ({ open, setOpen, useSearch, setParam }) => {
  const navigate = useNavigate();

  const submit = (values) => {
    const { venue_type, start_date, end_date } = values;
    console.log(end_date);
    setParam({ venue_type, start_date, end_date });
    navigate(`/venues/?test=123&venue_type=${venue_type}&start_date=${start_date}&end_date=${end_date}`);
  }

  const { values, errors, handleChange, touched, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      venue_type: useSearch.venue_type || 'all',
      start_date: useSearch.start_date || '',
      end_date: useSearch.end_date || ''
    },
    onSubmit: submit,
    validationSchema: WelcomePageFormValidation
  });

  return (
    <div className="card" id='search-event-card'>
      <div className="title toggle">
        <div></div>
        <h1>Book a Venue</h1>
        <AiOutlineCaretDown className='icon toggle' />
      </div>
      <form className="inputs row" onSubmit={handleSubmit}>
        <div className="col">
          <label htmlFor="type-of-event">Type of Event</label>
          <select
            className="form-select"
            aria-label="type-of-event"
            id='type-of-event'
            name='venue_type'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.venue_type}
          >
            <option value="all">All</option>
            {venue_type.map((option, index) => (
              <option key={index} value={option.value}>{option.label}</option>
            ))}
          </select>
          {touched.venue_type && errors.venue_type && (
            <p style={{ color: 'red' }}>{errors.venue_type}</p>
          )}
        </div>
        <div className="col">
          <label htmlFor="start_date">Start Date</label>
          <input
            id="start_date"
            className="form-control"
            type="date"
            name='start_date'
            value={values.start_date}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.start_date && errors.start_date && (
            <p style={{ color: 'red' }}>{errors.start_date}</p>
          )}
        </div>
        <div className="col">
          <label htmlFor="end_date">End Date</label>
          <input
            id="end_date"
            className="form-control"
            type="date"
            name='end_date'
            value={values.end_date}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.end_date && errors.end_date && (
            <p style={{ color: 'red' }}>{errors.end_date}</p>
          )}
        </div>

        <div className="col submit-col">
          <button type="submit" className="btn btn-primary">Submit</button>
          {typeof (open) !== 'undefined' && !open && (
            <BiFilterAlt className='icon' onClick={() => setOpen(true)} />
          )}
        </div>
      </form>
    </div>
  );
}

export default SearchEventCard;
