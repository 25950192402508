import React, { useState } from 'react'
import './forgot-password.scss'
import { Link, useNavigate } from 'react-router-dom'
import { instance } from '../../config/instance';
import AlertDialog from './AlertDialog';
import SnackbarAlert from '../../components/alert/Snackbar';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [open, setOpen] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const email = e.target.email.value;
        // console.log(email);
        // navigation('/login');        

        try {
            const res = await instance.get(`/customer/forgot-password/${email}`);
            // console.log(res);
            setOpen(true);
            setLoading(false);
        }
        catch (err) {
            setErr(false)
            console.log(err);
            setLoading(false);
            setErr(true);
        }
        // setLoading(false);
    }

    return (
        <>
            {<AlertDialog _open={open} _setOpen={setOpen} />}
            {err && <SnackbarAlert msg="Email Can't Sent." type='error' variant='filled' />}
            <div>
                <div className="container d-flex flex-column" id='reset-passwd'>
                    <div className="row align-items-center justify-content-center min-vh-100">
                        <div className="col-12 col-md-8 col-lg-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h5>Forgot Password?</h5>
                                        <p className="mb-2">Enter your registered email ID to reset the password
                                        </p>
                                    </div>
                                    <form onSubmit={submit}>
                                        <div className="mb-2">
                                            <label for="email" className="form-label">Email</label>
                                            <input type="email" id="email" className="form-control" name="email" placeholder="Enter Your Email" required />

                                        </div>
                                        <p></p>
                                        <div className="mb-3 d-grid">
                                            {
                                                loading ? (
                                                    <button type="submit" className="btn btn-primary" style={{ background: 'gray', borderColor: 'gray' }}>
                                                        <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                                        Reset Password
                                                    </button>
                                                ) : (
                                                    <button type="submit" className="btn btn-primary">
                                                        Reset Password
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <span>Don't have an account? <Link to="/register">Register</Link></span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword