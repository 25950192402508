import React from 'react'
import './form-element.scss'

export const TextInput = ({ parentClasses, label, labelClass, type, value, inputClass, touched, error, handleBlur, name, onChange, _id, required, placeholder }) => {
    return (
        <div className={parentClasses} style={{ width: "100%", minHeight: '60px' }}>
            {label && <label htmlFor={_id} className={labelClass}>{label} {required && <span className='require'>*</span>}</label> }
            <div style={{ width: '100%' }}>
                <input
                    type={type}
                    name={name}
                    className={inputClass + ' form-control'}
                    onChange={onChange}
                    value={value}
                    style={{ width: '100%' }}
                    onBlur={handleBlur}
                    id={_id}
                    placeholder={placeholder}
                />

                <div>
                    {touched && error ? <p style={{ color: 'red' }}>{error}</p> : null}
                </div>
            </div>
        </div>
    )
}

export const Checkbox = ({ parentClasses, label, labelClass, type, value, handleBlur, name, onChange, _id, required }) => {
    return (
        <div className={parentClasses} style={{ width: "100%", minHeight: '60px', display: 'inline', alignItems: 'center' }}>
           
            <div style={{ width: '100%' }}>
                <input
                    type={type}
                    name={name}
                    className={'form-check-input'}
                    onChange={onChange}
                    value={value}
                    style={{ marginRight: 9 }}
                    onBlur={handleBlur}
                    id={_id}
                />
                 <label htmlFor={_id} className={labelClass}>{label} {required && <span className='require'>*</span>}</label>
            </div>
        </div>
    )
}

export const Radio = ({ parentClasses, label, labelClass, type, value, handleBlur, name, onChange, _id, required }) => {
    return (
        <div className={parentClasses} style={{ width: "100%", minHeight: '60px', display: 'inline', alignItems: 'center' }}>
           
            <div style={{ width: '100%' }}>
                <input
                    type='radio'
                    name={name}
                    className={'form-check-input'}
                    onChange={onChange}
                    value={value}
                    style={{ marginRight: 9 }}
                    onBlur={handleBlur}
                    id={_id}
                />
                 <label htmlFor={_id} className={labelClass}>{label} {required && <span className='require'>*</span>}</label>
            </div>
        </div>
    )
}


export const FileInput = ({ parentClasses, label, labelClass, type, value, handleBlur, name, onChange, _id, required, touched, error, setVatImage }) => {
    return (
        <div className={parentClasses} style={{ width: "100%", minHeight: '60px' }}>
            {label && <label htmlFor={_id} className={labelClass}>{label} {required && <span className='require'>*</span>}</label> }
            <div style={{ width: '100%' }}>
                <input
                    type={type}
                    name={name}
                    className={'form-control'}
                    onChange={(e) => {
                        onChange(e)
                        setVatImage(e.target.files[0])
                    } }
                    value={value}
                    style={{ width: '100%' }}
                    onBlur={handleBlur}
                    id={_id}
                    // accept image format and pfd
                    accept="image/*,.pdf"
                    // max file size 2mb
                    maxfilesize={2000000}
                />

                <div>
                    {touched && error ? <p style={{ color: 'red' }}>{error}</p> : null}
                </div>
            </div>
        </div>
    )
}