import React, { useEffect, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "@mui/material/Button";
import { GetDateCategory } from "../../../utility/CommonFunc";
import { useFormik } from "formik";
import { VenueOptionQtyFormValidation } from "../../../validation/FormValidation";
import { MenuItem, TextField } from "@mui/material";


const ExpandTable = (props) => {
  const {
    options,
    addons,
    basic,
    date,
    blocked_slots,
    prevBooking,
    addOption,
    removeOption,
    addAddon,
    blocked_venue_slots,
    removeAddon,
    changeAddonQuantity,
  } = props;



  const [expand, setExpand] = useState(false);
  const active_date = GetDateCategory(date);

  const [time, setTime] = useState({
    start: null,
    end: null,
  });

  const [formattedTime, setFormattedTime] = useState({
    start: null,
    end: null,
  });

  const [timeErr, setTimeErr] = useState({
    start: true,
    end: false,
  });

  // Extract booked time slots from options
  const bookedSlots = blocked_slots || [];
  const blockedVenueSlots = blocked_venue_slots || [];

  // // Filter blocked slots for the selected date
  // const filteredSlots = [];

  // // Iterate through bookedSlots and add each blocked slot to filteredSlots if it matches the selected date
  // for (const slot of bookedSlots) {
  //   // Check if the booked slot's date matches the selected date
  //   if (
  //     new Date(slot.date).toISOString().split("T")[0] ===
  //     date.toISOString().split("T")[0]
  //   ) {
  //     // Add each blocked slot to filteredSlots
  //     for (const blockedSlot of slot.blocked_slots) {
  //       filteredSlots.push({
  //         start: blockedSlot.start,
  //         end: blockedSlot.end,
  //       });
  //     }
  //   }
  // }

  const toggle_option = (msg) => {
    return () => {
      if (msg === "open") {
        if (time.start === null || time.end === null) {
          return alert("Please select start and end time");
        }

        const selectedStartTime = new Date(time.start);
        const selectedEndTime = new Date(time.end);

        // Extracting start time in 24-hour format
        const startTimeHours = selectedStartTime.getHours();
        const startTimeMinutes = selectedStartTime.getMinutes();
        const startTimeFormatted = `${
          startTimeHours < 10 ? "0" : ""
        }${startTimeHours}:${
          startTimeMinutes < 10 ? "0" : ""
        }${startTimeMinutes}`;

        // Extracting end time in 24-hour format
        const endTimeHours = selectedEndTime.getHours();
        const endTimeMinutes = selectedEndTime.getMinutes();
        const endTimeFormatted = `${
          endTimeHours < 10 ? "0" : ""
        }${endTimeHours}:${endTimeMinutes < 10 ? "0" : ""}${endTimeMinutes}`;

        // Iterate through existing booked slots of Venues
        for (const booking of bookedSlots) {
          const bookingDate = new Date(booking.date);
          // Check if the selected date matches any existing booking
          if (
            bookingDate.toISOString().split("T")[0] ===
            date.toISOString().split("T")[0]
          ) {
            // Iterate through blocked slots for this date
            for (const slot of booking.blocked_slots) {
              let slotStartTime;
              let slotEndTime;

              if (slot.start.includes("T")) {
                slotStartTime = new Date(slot.start);

                // Extracting start time in 24-hour format
                const startTimeHours = slotStartTime.getHours();
                const startTimeMinutes = slotStartTime.getMinutes();
                slotStartTime = `${
                  startTimeHours < 10 ? "0" : ""
                }${startTimeHours}:${
                  startTimeMinutes < 10 ? "0" : ""
                }${startTimeMinutes}`;
              } else {
                slotStartTime = slot.start;
              }

              if (slot.end.includes("T")) {
                slotEndTime = new Date(slot.end);

                // Extracting end time in 24-hour format
                const endTimeHours = slotEndTime.getHours();
                const endTimeMinutes = slotEndTime.getMinutes();
                slotEndTime = `${endTimeHours < 10 ? "0" : ""}${endTimeHours}:${
                  endTimeMinutes < 10 ? "0" : ""
                }${endTimeMinutes}`;
              } else {
                slotEndTime = slot.end;
              }

              // Check if the selected time slot overlaps with any existing slot
              if (
                (startTimeFormatted >= slotStartTime &&
                  startTimeFormatted < slotEndTime) ||
                (endTimeFormatted > slotStartTime &&
                  endTimeFormatted <= slotEndTime) ||
                (startTimeFormatted <= slotStartTime &&
                  endTimeFormatted >= slotEndTime)
              ) {
                alert("This time slot is already booked!");
                return;
              }
            }
          }
        }

        // Iterate through new blocked slots by Admin
        for (const blockedSlot of blockedVenueSlots) {
          const slotDate = blockedSlot.date;

          if (slotDate === date.toISOString().split("T")[0]) {
            for (const slot of blockedSlot.blockedSlots) {
              const slotStartTime = slot.start_time;
              const slotEndTime = slot.end_time;

              if (
                (startTimeFormatted >= slotStartTime &&
                  startTimeFormatted < slotEndTime) ||
                (endTimeFormatted > slotStartTime &&
                  endTimeFormatted <= slotEndTime) ||
                (startTimeFormatted <= slotStartTime &&
                  endTimeFormatted >= slotEndTime)
              ) {
                alert("This time slot is blocked!");
                return;
              }
            }
          }
          // const slotStartTime = new Date(blockedSlot.start_time);
          // const slotEndTime = new Date(blockedSlot.end_time);

          // // Check if the selected date matches any new blocked slots
          // if (
          //   slotDate.toISOString().split("T")[0] ===
          //   date.toISOString().split("T")[0]
          // ) {
          //   // Check if the selected time slot overlaps with any new blocked slot
          //   if (
          //     (selectedStartTime >= slotStartTime &&
          //       selectedStartTime < slotEndTime) ||
          //     (selectedEndTime > slotStartTime &&
          //       selectedEndTime <= slotEndTime) ||
          //     (selectedStartTime <= slotStartTime &&
          //       selectedEndTime >= slotEndTime)
          //   ) {
          //     alert("This time slot is blocked!");
          //     return;
          //   }
          // }
        }

        // Iterate through prevBooking options That are already booked
        for (const option of prevBooking.options) {
          const bookedStartTime = new Date(option.time.start);
          const bookedEndTime = new Date(option.time.end);

          // Extracting start time in 24-hour format
          const startTimeHours = bookedStartTime.getHours();
          const startTimeMinutes = bookedStartTime.getMinutes();
          const fomattedStartTime = `${
            startTimeHours < 10 ? "0" : ""
          }${startTimeHours}:${
            startTimeMinutes < 10 ? "0" : ""
          }${startTimeMinutes}`;

          // Extracting end time in 24-hour format
          const endTimeHours = bookedEndTime.getHours();
          const endTimeMinutes = bookedEndTime.getMinutes();
          const formattedEndTime = `${
            endTimeHours < 10 ? "0" : ""
          }${endTimeHours}:${endTimeMinutes < 10 ? "0" : ""}${endTimeMinutes}`;

          // Check if the selected time slot overlaps with any booked slot
          // if (
          //   (selectedStartTime >= bookedStartTime &&
          //     selectedStartTime < bookedEndTime) ||
          //   (selectedEndTime > bookedStartTime &&
          //     selectedEndTime <= bookedEndTime) ||
          //   (selectedStartTime <= bookedStartTime &&
          //     selectedEndTime >= bookedEndTime)
          // ) {
          //   alert("You booked this time slot already!");
          //   return;
          // }
          if (
            (startTimeFormatted >= fomattedStartTime &&
              startTimeFormatted < formattedEndTime) ||
            (endTimeFormatted > fomattedStartTime &&
              endTimeFormatted <= formattedEndTime) ||
            (startTimeFormatted <= fomattedStartTime &&
              endTimeFormatted >= formattedEndTime)
          ) {
            alert("You booked this time slot already!");
            return;
          }
        }

        setExpand(true);

        // Extracting start time in 24-hour format
        const startHours = selectedStartTime.getHours();
        const startMinutes = selectedStartTime.getMinutes();
        const startFormatted = `${startHours < 10 ? "0" : ""}${startHours}:${
          startMinutes < 10 ? "0" : ""
        }${startMinutes}:00`;

        // Extracting end time in 24-hour format
        const endHours = selectedEndTime.getHours();
        const endMinutes = selectedEndTime.getMinutes();
        const endFormatted = `${endHours < 10 ? "0" : ""}${endHours}:${
          endMinutes < 10 ? "0" : ""
        }${endMinutes}:00`;

        // addOption(date, { ...options, time: { ...time }, active_date });
        addOption(date, {
          ...options,
          time: { ...time },
          formattedtime: { start: startFormatted, end: endFormatted },
          active_date,
        });
      } else {
        setExpand(false);
        removeOption(date, options.option_id);
      }
    };
  };

  const handleTimeChange = (time, name) => {
    const selectedTime = new Date(time);

    // Extracting start time in 24-hour format
    const TimeHours = selectedTime.getHours();
    const TimeMinutes = selectedTime.getMinutes();
    const TimeFormatted = `${TimeHours < 10 ? "0" : ""}${TimeHours}:${
      TimeMinutes < 10 ? "0" : ""
    }${TimeMinutes}:00`;

    if (name === "start") {
      setTime((prevTime) => ({
        ...prevTime,
        start: prevTime.start.toLocaleString(),
      }));
      setFormattedTime((prevFormattedTime) => ({
        ...prevFormattedTime,
        start: TimeFormatted,
      }));
    } else {
      setTime((prevTime) => ({
        ...prevTime,
        end: prevTime.end.toLocaleString(),
      }));
      setFormattedTime((prevFormattedTime) => ({
        ...prevFormattedTime,
        end: TimeFormatted,
      }));
    }
  };

  useEffect(() => {
    setExpand(false);
    setTime({ start: null, end: null });
  }, [date]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <tr style={{ border: "1px solid #fff", width: "30%" }}>
        <td style={{ border: "1px solid #fff", width: "40%" }}>
          <b>{options.title}</b>
        </td>
        <td style={{ border: "1px solid #fff", width: "20%" }}>
          <b>
            LKR{" "}
            {parseFloat(options[active_date]).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </b>
        </td>
        <td style={{ border: "1px solid #fff", width: "20%" }}>
          <span
            className="d-flex flex-row bd-highlight"
            style={{ maxWidth: "150px" }}
          >
            <TimePicker
              value={time.start}
              label="Start Time"
              style={{ height: "50px", borderColor: "red" }}
              name="start"
              // onChange={(e) => handleTimeChange(e, "start")}
              onChange={(e) => setTime({ ...time, start: e.toLocaleString() })}
              // onChange={(newTime) => handleTimeChange(newTime, true)}
            />
          </span>
        </td>
        <td style={{ border: "1px solid #fff", width: "20%" }}>
          <span
            className="d-flex flex-row bd-highlight"
            style={{ maxWidth: "150px" }}
          >
            <TimePicker
              label="End Time"
              style={{ width: "100%" }}
              // ampm={false}
              name="end"
              onChange={(e) => setTime({ ...time, end: e.toLocaleString() })}
              // onChange={(e) => handleTimeChange(e, "end")}
              // onChange={(newTime) => handleTimeChange(newTime, false)}
              value={time.end}
            />
          </span>
        </td>
        <td style={{ border: "1px solid #fff", width: "20%" }}>
          <span
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            {expand ? (
              <Button
                variant="contained"
                sx={{
                  width: "120px",
                  background: "#C17603",
                  color: "#fff",
                  "&:hover": { background: "red" },
                }}
                onClick={toggle_option("close")}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ width: "120px", background: "#C17603", color: "#fff" }}
                onClick={toggle_option("open")}
              >
                Book Now
              </Button>
            )}
          </span>
        </td>
      </tr>

      <tr>
        <td
          colSpan="5"
          style={{ border: "none", backgroundColor: "#fff", padding: "0" }}
        >
          <form>
            <table
              className="table"
              style={{
                borderCollapse: "collapse",
                borderColor: "#fff",
                maxWidth: "700px",
                marginTop: 10,
              }}
            >
              {expand && (
                <tbody>
                  {addons?.map((addon, index) => {
                    return (
                      <AddOnsRow
                        key={index}
                        addon={addon}
                        addAddon={addAddon}
                        removeAddon={removeAddon}
                        date={date}
                        options={options}
                        changeAddonQuantity={changeAddonQuantity}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          </form>
        </td>
      </tr>
    </LocalizationProvider>
  );
};

export default ExpandTable;

const AddOnsRow = ({
  addon,
  addAddon,
  removeAddon,
  date,
  options,
  changeAddonQuantity,
}) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    if (e.target.value === "") {
      return setError("Please enter a valid number");
    }
    if (e.target.value > addon.max_qty) {
      return setError("Maximum quantity exceeded");
    }
    if (e.target.value < 0) {
      return setError("Please enter a valid number");
    }

    // only allow numbers
    if (isNaN(e.target.value)) {
      return setError("Please enter a valid number");
    }
    setError("");

    changeAddonQuantity(
      date,
      options.option_id,
      addon.add_ons_id,
      parseInt(e.target.value)
    );
  };

  // date, option_id, addon_id, quantity

  const change_addon_checkbox = (e, addon) => {
    if (e.target.checked) {
      addAddon(date, options.option_id, { ...addon, quantity: 1 });
      setChecked(true);
    } else {
      removeAddon(date, options.option_id, addon.add_ons_id);
      setChecked(false);
    }
  };
  return (
    <tr>
      <td>{addon.add_ons_name}</td>
      <td></td>
      {/* <td>{addon.cost == 0 ? 'FOC' : `LKR ${parseFloat(addon.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
      <td>
        {addon.cost == 0
          ? "FOC"
          : `LKR ${parseFloat(addon.cost).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
      </td>
      <td></td>
      <td>
        <input
          type="checkbox"
          onClick={(e) => change_addon_checkbox(e, addon)}
        />
        {addon.max_qty > 1 && checked && (
          <input
            type="text"
            style={{
              width: 60,
              height: 30,
              padding: 5,
              marginLeft: 40,
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#000",
              borderRadius: 5,
              outline: "none",
            }}
            placeholder="Qty"
            onChange={handleChange}
            defaultValue={1}
          />
        )}
        <p
          style={{
            color: "red",
            fontSize: 12,
            marginTop: 5,
            position: "absolute",
            marginLeft: "40px",
          }}
        >
          {error}
        </p>
      </td>
    </tr>
  );
};
