import React, { useEffect, useState } from "react";
import "./venues.scss";
import SearchEventCard from "../../components/search-event-card/SearchEventCard";
import FilterSideBar from "../../components/filter-side-bar/FilterSideBar";
import VenueCard from "../../components/venue-card/VenueCard";
import { instance } from "../../config/instance";
import CircularProgress from "@mui/material/CircularProgress";
import { useBooking } from "../../context/BookingContext";

const Venues = () => {

  const { bookingData } = useBooking();
  // console.log("Booking Data:", bookingData);

  // Get the current URL
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(currentUrl);

  const [param, setParam] = useState({
    start_date: urlParams.get("start_date"),
    venue_type: urlParams.get("venue_type"),
    end_date: urlParams.get("end_date"),
  });

  const [sideBarOpen, setSideBarOpen] = useState();
  const [venue, setVenue] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const windowSize = window.innerWidth;
      if (windowSize < 880) {
        setSideBarOpen(false);
      } else {
        setSideBarOpen(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const getVenues = async () => {
      let queryParam = `?check=abc&start_date=${param.start_date}&end_date=${param.end_date}&venue_type=${param.venue_type}`;
      try {
        
        const res = await instance.get(`/customer/venues${queryParam}`);
        if (res.status === 200) {
          setVenue(res.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    getVenues();
  }, [param.start_date, param.venue_type, param.end_date]);

  if (sideBarOpen == null) {
    return <div>Loading...</div>;
  }

  return (
    <div id="events">
      <div className="event-search">
        <SearchEventCard
          open={sideBarOpen}
          setOpen={setSideBarOpen}
          setParam={setParam}
          useSearch={{
            venue_type: param.venue_type,
            start_date: param.start_date,
            end_date: param.end_date,
          }}
        />
      </div>
      <section>
        <p className="section-title">Search Criteria</p>

        <div className="section-container">
          {sideBarOpen && <div className="back-drop"></div>}
          <div className="side-bar">
            {/* {sideBarOpen && <FilterSideBar setOpen={setSideBarOpen} />} */}
          </div>
          <div className="venue-card-section">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  maxHeight: "100px",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : venue?.length > 0 ? (
              venue.map((venue, index) => {
                // console.log('Rendering venue:', venue);
                return <VenueCard key={index} venue={venue} param={param} />;
              })
            ) : (
              <div
                className="card"
                style={{
                  height: "100%",
                  maxHeight: "100px",
                  boxShadow: "1px 4px 21.100000381469727px 0px #C2C2C240",
                }}
              >
                <div className="card-body text-center">
                  <h4 className="text-center">Not Venue Found</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Venues;
