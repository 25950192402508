import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import './reset.scss'
import { PasswordUpdateFormValidation } from '../../validation/FormValidation';
import { useNavigate } from 'react-router-dom';
import { instance } from '../../config/instance';
import SnackbarAlert from '../../components/alert/Snackbar';

const ResetPassword = () => {
    const navigation = useNavigate();
    const [tokenData, setTokenData] = useState({ token: '', id: '' });
    const [err, setErr] = useState(false);

    useEffect(() => {
        const token = window.location.pathname.split('/')[2];
        const id = window.location.pathname.split('/')[3];
        // console.log(token, id);
        if (!token || !id) {
            navigation('/login');
        }
        setTokenData({ token, id });
    }, [])

    const [loading, setLoading] = useState(false);

    const submit = async (value) => {
        setLoading(true)
        setErr(false)
        const { password, confirmPassword } = value;

        try {
            const res = await instance.post(`/customer/passwd-reset/${tokenData.token}/${tokenData.id}`, { password, confirmPassword });
            console.log(res);
            setLoading(false)
            navigation('/login?reset_password=true');
        }
        catch (err) {
            console.log(err);
            setLoading(false)
            setErr(true)
        }
    }

    const { values, errors, handleChange, touched, handleBlur, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: (values) => submit(values),
        validationSchema: PasswordUpdateFormValidation
    })

    const fields = [
        {
            _id: 1,
            label: "New Password",
            type: "password",
            name: "password",
            value: values.password,
            error: errors.password,
            touched: touched.password,
            required: true,
            onChange: handleChange,
            onBlur: handleBlur
        },
        {
            _id: 2,
            label: "Confirm New Password",
            type: "password",
            name: "confirmPassword",
            value: values.confirmPassword,
            error: errors.confirmPassword,
            touched: touched.confirmPassword,
            required: true,
            onChange: handleChange,
            onBlur: handleBlur
        },

    ]

    return (
        <>
        {err && <SnackbarAlert msg="Password Reset Failed" type='error' variant='filled' />}
            <div id='reset'>
                <div className="container d-flex flex-column" id='reset-passwd'>
                    <div className="row align-items-center justify-content-center min-vh-100">
                        <div className="col-12 col-md-8 col-lg-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <h5>Update Password</h5>
                                        <p className="mb-1">Enter your new password.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-2">

                                            {
                                                fields.map((field, index) => {
                                                    return (
                                                        <div style={{ width: '100%' }} key={index}>
                                                            <label htmlFor={field.name} className="form-label my-2">{field.label} <span className="require">*</span></label>
                                                            <input
                                                                type={field.type}
                                                                className={`form-control ${field.error && field.touched ? 'is-invalid' : 'custom-input'}`}
                                                                id={field.name}
                                                                name={field.name}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                onBlur={field.onBlur}
                                                            // placeholder={`Enter ${field.label} here`}
                                                            />
                                                            {field.error && field.touched ? <div className="invalid-feedback">{field.error}</div> : null}
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        <div className="my-3 d-grid">
                                            {
                                                loading ? (
                                                    <button type="submit" className="btn btn-primary" style={{ background: 'gray', borderColor: 'gray' }}>
                                                        <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                                        Update Password
                                                    </button>
                                                ) : (
                                                    <button type="submit" className="btn btn-primary">
                                                        Update Password
                                                    </button>
                                                )
                                            }
                                        </div>
                                        {/* <span>Don't have an account? <Link to="/login">sign in</Link></span> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword