import React from "react";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Welcome from "./pages/home/Welcome";
import PublicLayout from "./layout/PublicLayout";
import useAuthStore from "./utility/store/AuthStore";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ForgotPassword from "./pages/forgot-passwd/ForgotPassword";
import ActivateAcc from "./pages/account-activate/ActivateAcc";
import Venues from "./pages/venues/Venues";
import Venue from "./pages/venue/Venue";
import FormAndPricing from "./pages/booking-form-and-pricing/FormAndPricing";
import Success from "./components/thank-you-msg/Success";
import InvoicePay from "./pages/invoice/InvoicePay";
import Payment_Success from "./components/thank-you-msg/Payment_Success";

const App = () => {
  const auth = useAuthStore((state) => state.authState);

  const AuthRoute = ({ children }) => {
    return auth === true ? <Navigate to="/" /> : children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout />,
      children: [
        {
          path: "",
          element: <Welcome />,
        },
        {
          path: "venues",
          element: <Venues />,
        },
        {
          path: "venue/:id",
          element: <Venue />,
        },
        {
          path: "booking-form",
          element: <FormAndPricing />,
        },
        {
          path: "booking-success",
          element: <Success />,
        },
        {
          path: "payment/success/:bid/:id",
          element: <Payment_Success />,
        },
        {
          path: "booking/invoice/pay/:id",
          element: <InvoicePay />,
        },
      ],
    },
    {
      path: "/login",
      element: <AuthRoute children={<Login />} />,
    },
    {
      path: "/register",
      element: <AuthRoute children={<Register />} />,
    },
    {
      path: "/forgot-password",
      element: <AuthRoute children={<ForgotPassword />} />,
    },
    {
      path: "/reset-password/*",
      element: <AuthRoute children={<ResetPassword />} />,
    },
    {
      path: "/acc-activate/*",
      element: <AuthRoute children={<ActivateAcc />} />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
