import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { instance } from '../../config/instance';

const ActivateAcc = () => {
    const navigation = useNavigate();
    const [res, setRes] = useState({type: '', message: ''});
    const [tokenData, setTokenData] = useState({token: '', id: ''});

    useEffect(() => {
        const token = window.location.pathname.split('/')[2];
        const id = window.location.pathname.split('/')[3];
        // console.log(token, id);
        if (!token || !id) {
            setRes({type: 'error', message: 'Invalid token'});
        }


        const activate = async () => {
            try {
                const res = await instance.post(`/customer/acc-activate/${token}/${id}`);
                // console.log(res);
                setRes({type: 'success', message: "Account activated successfully"});
                
            }
            catch (err) {
                console.log(err);
                setRes({type: 'error', message: "Invalid token"});
            }
        }
        activate();
    }, [])

    if(res.type === ""){
        return <div>Activating...</div>
    }

    if(res.type === "error"){
        return <div>{res.message}</div>
    }

    return (
        <div id='activationSuccess'  style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <p>Account activate successfully.</p>
            <Link to='/' style={{ color: '#000'}}>Home</Link>
        </div>
    )
}

export default ActivateAcc