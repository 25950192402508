import { instance } from "../config/instance";


export const checkUserAuth = async () => {
    try {
        const res = await instance.get('customer/auth')
        if (res.status === 200) {
            return true;
        } else {
            return false;
        }
    }
    catch (e) {
        return false;
    }
}

export const GetDateCategory = (date) => {
    const day = date.getDay();

    if (day === 1) {
        return 'mon';
    } else if (day === 2 || day === 3 || day === 4) {
        return 'tue_wed_thu';
    } else {
        return 'fri_sat_sun';
    }
}