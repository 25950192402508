import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const AddonsSection = ({ addon }) => {
  const { quantity, cost } = addon;

  let totalCost = quantity * parseFloat(cost);
  // console.log(totalCost);
  const formatCost = totalCost.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="addons">
      <div className="title-section">
        <p>
          {addon.add_ons_name}{" "}
          {parseInt(quantity) > 1 && <span>x{quantity}</span>}
        </p>
        <p className="price">LKR {formatCost}</p>
      </div>
    </div>
  );
};

const OptionSection = (props) => {
  let totalCost = 0;

  // console.log(props.option);

  const {
    title,
    time,
    initial_booking_hours,
    active_date,
    price_per_additional_hour,
  } = props.option;

  // const initial_hours = parseFloat(initial_booking_hours);

  // // time different in hours
  // const start = new Date(time.start).getTime();
  // const end = new Date(time.end).getTime();

  // const timeDiff = end - start;
  // const hours = timeDiff / (1000 * 60 * 60);

  // // initial_booking_hour_exceeded
  // if (initial_hours >= hours) {
  //   totalCost = totalCost + parseFloat(props.option[active_date]);
  // } else {
  //   const additional_hours = hours - initial_hours;
  //   console.log('Price per Additional Hour:', price_per_additional_hour);
  //   totalCost =
  //     totalCost +
  //     (parseFloat(props.option[active_date]) +
  //       parseFloat(price_per_additional_hour) * Math.ceil(additional_hours));

        
  // }

  const initial_hours = parseFloat(initial_booking_hours);

// time difference in milliseconds
const start = new Date(time.start).getTime();
const end = new Date(time.end).getTime();
const timeDiff = end - start;

// convert milliseconds to hours (including fractional hours)
const hours = timeDiff / (1000 * 60 * 60);

// calculate additional hours precisely
const additional_hours = Math.max(0, hours - initial_hours);
const additional_hours_decimal = additional_hours % 1; // fractional part of hours

// console.log('Total Event Hours:', hours);
// console.log('Additional Hours (Decimal):', additional_hours_decimal);
// console.log('Price per Additional Hour:', price_per_additional_hour);

if (initial_hours >= hours) {
  totalCost = totalCost + parseFloat(props.option[active_date]);
} else {
  totalCost = totalCost +
    (parseFloat(props.option[active_date]) +
      parseFloat(price_per_additional_hour) * additional_hours);
}

  

  const formatCost = totalCost.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="option">
      <div className="option_title">
        <h6>{title}</h6>
        <p>LKR {formatCost}</p>
      </div>

      {props.option.addons?.map((addon, index) => {
        return <AddonsSection key={index + 1000} addon={addon} />;
      })}
    </div>
  );
};

const PricingMoreDetails = (props) => {
  const { bookingData, setPopup } = props;
  // console.log(bookingData);
  return (
    <div id="PricingMoreDetails">
      <div className="card">
        {/* <AiOutlineClose className='close-icon' onClick={setPopup(false)} /> */}

        {bookingData?.map((data, index) => {
          return (
            <div key={index}>
              <h5 className="date">
                {new Date(data?.date).toLocaleDateString("en-IN", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </h5>

              {data.options?.map((option, index) => {
                return (
                  <>
                    <OptionSection key={index} option={option} />
                    {props.needArrAndDis && (
                      <>
                        <div class="flex-item">
                          <h5>Arrangement and Dismantling Cost</h5>
                        </div>
                        <div class="">
                          <div class="">
                            <div class="">
                              <div class="d-flex flex-row justify-content-between">
                                <h6>Arrangement Cost</h6>
                                <p>
                                  LKR{" "}
                                  {props.arrangementCost.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </p>
                              </div>
                              <div class="d-flex flex-row justify-content-between">
                                <h6>Dismantling Cost</h6>
                                <p>
                                  LKR{" "}
                                  {props.dismantlingCost.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingMoreDetails;
