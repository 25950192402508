import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import './register.scss'
import Image from '../../assets/img/bmich_login_image.png'
import Logo from '../../assets/img/Logo.png'
import { RegisterFormValidation } from '../../validation/FormValidation';
import { instance } from '../../config/instance';
import SnackbarAlert from '../../components/alert/Snackbar';
import Copyright from '../../components/cpyright.jsx/Copyright';

const Register = () => {
    const navigation = useNavigate()

    const [error, setError] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const register = async (values) => {
        setError(false)
        setLoading(true)
        try {
            const data = {
                name: values.name,
                email: values.email,
                password: values.password,
                confirmPassword: values.confirmPassword
            }

            const res = await instance.post('/customer/register', data)
            if (res.status === 200) {
                try {
                    const res = await instance.post(`/customer/activation-link/${data.email}`)
                    if (res.status === 200) {
                        setRegistrationSuccess(true)
                    }
                }
                catch (err) {
                    console.log(err)
                }
            }
            setLoading(false)
        }
        catch (err) {
            console.log(err)
            if (err.response.status === 400 && err.response.data.message === 'Email address already exists') {
                setFieldError('email', 'Email address already exists')
                setLoading(false)
            }
            setError(true)
            setLoading(false)
        }
    }

    const { values, errors, handleChange, touched, handleBlur, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: RegisterFormValidation,
        onSubmit: (values) => register(values),
    })

    const fields = [
        {
            _id: 1,
            label: "Name",
            type: "name",
            name: "name",
            value: values.name,
            error: errors.name,
            touched: touched.name,
            onChange: handleChange,
            onBlur: handleBlur
        },
        {
            _id: 2,
            label: "Email",
            type: "email",
            name: "email",
            value: values.email,
            error: errors.email,
            touched: touched.email,
            onChange: handleChange,
            onBlur: handleBlur
        },
        {
            _id: 3,
            label: "Password",
            type: "password",
            name: "password",
            value: values.password,
            error: errors.password,
            touched: touched.password,
            onChange: handleChange,
            onBlur: handleBlur
        },
        {
            _id: 4,
            label: "Confirm Password",
            type: "password",
            name: "confirmPassword",
            value: values.confirmPassword,
            error: errors.confirmPassword,
            touched: touched.confirmPassword,
            onChange: handleChange,
            onBlur: handleBlur
        }

    ]

    if (registrationSuccess) {
        return (
            <div id='registrationSuccess' style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <p>Thanks for your registration. Please check you emails for the confirmation email.</p>
                <Link to='/' style={{ color: '#000' }}>Home</Link>
            </div>
        )
    }
    return (
        <div id='register'>
            {error && <SnackbarAlert msg='Registration failed' type='error' variant='filled' />}
            <div className='book-venue-btn'><button onClick={() => navigation('/')} >Book a Venue</button></div>
            <div className="card-container">
                <div className="left column">
                    <img src={Image} alt="image" />
                </div>
                <div className="right column">

                    <div className="head">
                        <div className="logo">
                            <img src={Logo} alt="logo" />
                        </div>
                        <p>CREATE YOUR ACCOUNT</p>
                    </div>

                    <div className="form-container">
                        <form onSubmit={handleSubmit}>

                            {
                                fields.map((field, index) => {
                                    return (
                                        <div style={{ width: '100%' }} key={index}>
                                            <label htmlFor={field.name} className="col-3  mb-1" style={{ width: '200px' }}>{field.label} <span className="require">*</span></label>
                                            <input
                                                type={field.type}
                                                className={`form-control ${field.error && field.touched ? 'is-invalid' : 'custom-input'}`}
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                            // placeholder={`Enter ${field.label} here`}
                                            // style={{padding: '10px 10px'}}
                                            />
                                            <div>
                                                {field.touched && field.error ? <p style={{ color: 'red' }}>{field.error}</p> : <div style={{ height: '20px' }}></div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {/* <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                        </button> */}

                            {
                                loading ? <button class="submit-button" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Registering...</span>
                                </button> : <button type='submit' className='submit-button'>Register</button>
                            }

                            {/* <button type='submit' className='submit-button'>Register</button> */}
                            <Link to='/login' className='for-sign-up-link'>Do you have an account? <span>Login here</span></Link>

                        </form>
                    </div>

                </div>
            </div>
            <Copyright />
        </div>
    )
}

export default Register