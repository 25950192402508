import React from 'react'
import './venue-card.scss'
import { base_url } from '../../config/instance';
import { useNavigate } from 'react-router-dom';
import  { useEffect, useState } from "react";


const VenueCard = ({ venue, param }) => {

    const navigate = useNavigate();
    const navigation = useNavigate()
    const {
        id,
        name,
        thumbnail,
        description,
        min_const,
        gallery,
        capacity,
        stage,
        floor_area,
        ceiling_height,
        venue_type,
        floor_plan,
        refundable_deposit,
        advance_payment,
        cancelation_fee,
        active
    } = venue;

//     return (
//         <div id='venue-card' className='card'>
//             <div className="image">
//                 <img src={`${base_url}/public/venue-image/${thumbnail}`} alt="venue" />
//                 {/* <img src="https://picsum.photos/900/600" alt="venue" /> */}
//             </div>
//             <div className="details">
//                 <div className="top">
//                     <h1 className="title">{name}</h1>
//                     <p className="description">{
//                         description.length > 160 ? `${description.substring(0, 130)} ...` : description
//                     }</p>

//                     <table>
//                         <tbody>
//                             {capacity !== 1 && capacity !== '1' && <tr>
//                                 <td>Capacity (No. of pax)</td>
//                                 <td>: {capacity}</td>
//                             </tr>}
                            
//                             {floor_area !== '1' && floor_area !== 1 && <tr>
//                                 <td>Floor size (Sq ft)</td>
//                                 <td>: {floor_area}</td>
//                             </tr>}
//                         </tbody>
//                     </table>
//                 </div>

//                 <div className="price">
//                     {/* parseFloat(min_const).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) */}
//                     <div className="amount">LKR {parseFloat(min_const).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
//                     <div className="btn">
//                         <button className="btn btn-primary" onClick={() => {
//                             console.log(`Navigating to: /venue/${id}/?test=123&venue_type=${param.venue_type}&start_date=${param.start_date}&end_date=${param.end_date}`);
                                                        
//                             navigation(`/venue/${id}/?test=123&venue_type=${param.venue_type}&start_date=${param.start_date}&end_date=${param.end_date}`)
//                         }}>Book Now</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default VenueCard

return (
    
    <div id='venue-card' className='card'>
        
        <div className="image">
            <img src={`${base_url}/public/venue-image/${thumbnail}`} alt="venue" />
            {/* <img src="https://picsum.photos/900/600" alt="venue" /> */}
        </div>
        <div className="details">
            <div className="top">
                <h1 className="title">{name}</h1>
                <p className="description">
                    {description.length > 160 ? `${description.substring(0, 130)} ...` : description}
                </p>

                <table>
                    <tbody>
                        {capacity !== 1 && capacity !== '1' && (
                            <tr>
                                <td>Capacity (No. of pax)</td>
                                <td>: {capacity}</td>
                            </tr>
                        )}

                        {floor_area !== '1' && floor_area !== 1 && (
                            <tr>
                                <td>Floor size (Sq ft)</td>
                                <td>: {floor_area}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="price">
                <div className="amount">
                    LKR {parseFloat(min_const).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
                <div className="btn">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            // console.log(`Navigating to: /venue/${id}/?test=123&venue_type=${param.venue_type}&start_date=${param.start_date}&end_date=${param.end_date}`);
                            navigate(`/venue/${id}/?test=123&venue_type=${param.venue_type}&start_date=${param.start_date}&end_date=${param.end_date}`);
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>

    </div>
);
}

export default VenueCard;