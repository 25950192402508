import React from "react";
import { Link, useLocation } from "react-router-dom"; // Assuming you're using React Router for navigation
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Success = () => {
  const location = useLocation();
  // Destructure location from props to access state

  // Check if state fromBookingPage is present
  const isFromBookingPage = location.state && location.state.fromBookingPage;

  // If not coming from the booking page, redirect to booking page
  //   if (!isFromBookingPage) {
  //     return (
  //       <>
  //         <div id="margin"></div>
  //         <p>Loading...</p>
  //       </>
  //     );
  //   }

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card text-center shadow p-3 mb-5 bg-body rounded-3">
        <div className="card-body">
          <h5 className="card-title ">
            <CheckCircleOutlineIcon /> Booking Success!{" "}
          </h5>
          <p className="card-text">
            Thank you, Your reservation has been successfully booked.
          </p>
          <Link to="/" className="btn text-light " style={{ backgroundColor: "#c17603" }}>
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
