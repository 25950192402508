import React, { useEffect, useState } from 'react'
import './welcome.scss'
import SnackbarAlert from '../../components/alert/Snackbar'
import { venue_type } from '../../config/constance'
import { useFormik } from 'formik'
import { WelcomePageFormValidation } from '../../validation/FormValidation'
import { useNavigate } from 'react-router-dom'
import Video from '../../assets/video/BMICH-video.mp4'
import Copyright from '../../components/cpyright.jsx/Copyright'

const Welcome = () => {
  const navigation = useNavigate()
  const [msg, setMsg] = useState({ open: false, msg: '', type: '' })

  const submit = ({ venue_type, start_date, end_date }) => {
    navigation(`/venues?check=abc&venue_type=${venue_type}&start_date=${start_date}&end_date=${end_date}`)
  }

  const { values, errors, handleChange, touched, handleBlur, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
    initialValues: {
      venue_type: 'all',
      start_date: '',
      end_date: ''
    },
    onSubmit: (values) => submit(values),
    validationSchema: WelcomePageFormValidation
  })

  useEffect(() => {
    const url = window.location.href
    if (url.includes('login=success')) {
      setMsg({ open: true, text: 'Login Success', type: 'success' })
    }
  }, [])
  return (
    <div id='welcome'>
      {msg.open && <SnackbarAlert msg={msg.text} type={msg.type} variant='filled' />}
      <div className='top-margin'></div>
      {/* <div className='content'>
        <div className="whit"></div>
        <div className="video-container">
          <video autoPlay loop muted>
            <source src={Video} type="video/mp4" />
          </video>
        </div>
        <div className="card-container">
          <div className="card">
            <h1>Book a Venue</h1>
            <form className="inputs row" onSubmit={handleSubmit}>
              <div className="row">
                <label htmlFor="type-of-event">Type of Event</label>
                <select
                  className="form-select"
                  aria-label="type-of-event"
                  id='type-of-event'
                  name='venue_type'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.venue_type}
                >
                  <option value='all'> All </option>
                  {
                    venue_type.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>{option.label}</option>
                      )
                    })
                  }
                </select>
                <span>
                  {touched.venue_type && errors.venue_type ? <p style={{ color: 'red' }}>{errors.venue_type}</p> : <div style={{ height: '20px' }}></div>}
                </span>
              </div>
              <div className="row">
                <label htmlFor="startDate">Start Date</label>
                <input
                  id="startDate"
                  className="form-control"
                  type="date"
                  name='start_date'
                  value={values.start_date}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />
                <span>
                  {touched.start_date && errors.start_date ? <p style={{ color: 'red' }}>{errors.start_date}</p> : <div style={{ height: '20px' }}></div>}
                </span>
              </div>
              <div className="row">
                <label htmlFor="endDate">End Date</label>
                <input
                  id="endDate"
                  className="form-control"
                  type="date"
                  name='end_date'
                  value={values.end_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>
                  {touched.end_date && errors.end_date ? <p style={{ color: 'red' }}>{errors.end_date}</p> : <div style={{ height: '20px' }}></div>}
                </span>
              </div>

              <div className="col submit-col" >
                <label htmlFor="endDate">submit button</label>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>


          <div className="bottom-line"></div>

        </div>
      </div> */}

      <div className="content-new">
        <video autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>

        <div className="card">
          <h1>Book a Venue</h1>
          <form className="inputs" onSubmit={handleSubmit}>
            <div className="row">
              <label htmlFor="type-of-event">Type of Event</label>
              <select
                className="form-select"
                aria-label="type-of-event"
                id='type-of-event'
                name='venue_type'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.venue_type}
              >
                <option value='all'> All </option>
                {
                  venue_type.map((option, index) => {
                    return (
                      <option key={index} value={option.value}>{option.label}</option>
                    )
                  })
                }
              </select>
              <span>
                {touched.venue_type && errors.venue_type ? <p style={{ color: 'red' }}>{errors.venue_type}</p> : <div style={{ height: '20px' }}></div>}
              </span>
            </div>
            <div className="row">
              <label htmlFor="startDate">Start Date</label>
              <input
                id="startDate"
                className="form-control"
                type="date"
                name='start_date'
                value={values.start_date}
                onChange={handleChange}
                onBlur={handleBlur}

              />
              <span>
                {touched.start_date && errors.start_date ? <p style={{ color: 'red' }}>{errors.start_date}</p> : <div style={{ height: '20px' }}></div>}
              </span>
            </div>
            <div className="row">
              <label htmlFor="endDate">End Date</label>
              <input
                id="endDate"
                className="form-control"
                type="date"
                name='end_date'
                value={values.end_date}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span>
                {touched.end_date && errors.end_date ? <p style={{ color: 'red' }}>{errors.end_date}</p> : <div style={{ height: '20px' }}></div>}
              </span>
            </div>

            <div className="submit" >
              {/* <label htmlFor="endDate">submit button</label> */}
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div className="copyright">
      <Copyright />
      </div>
    </div>
  )
}

export default Welcome