import React, { useEffect, useState } from 'react'
import './nav.scss'
import Logo from '../../assets/img/logo.png'
import { useNavigate } from 'react-router-dom'
import { instance } from '../../config/instance';
import { RxAvatar } from "react-icons/rx";
import useAuthStore from '../../utility/store/AuthStore';


const IsLogin = () => {
  const setAuth = useAuthStore(state => state.setAuth);

  const logout = async () => {

    try {
      const res = await instance.get('/customer/logout');
      if (res.status === 200) {
        setAuth(false);
      }
    }
    catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      <div className='logged'>
        <div className="dropdown">

          <a className="btn btn-secondary" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            <RxAvatar className='icon' />
          </a>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><a className="dropdown-item" onClick={logout}>Logout</a></li>
          </ul>

        </div>
      </div>
    </>
  )

}

const IsNotLogin = () => {
  const navigation = useNavigate()
  return (
    <div className='not-login'>
      <button className='reg-btn' onClick={() => navigation('/register')}>register</button>
      <button className='login-btn' onClick={() => navigation('/login')}>login</button>
    </div>
  )

}

const Nav = () => {
  const navigation = useNavigate();
  const auth = useAuthStore(state => state.authState);

  return (
    <div id='nav'>
      <div className='left'>
        <img src={Logo} alt="logo" onClick={() => navigation('/')} />
      </div>
      {/* <div className='right'>
        {auth ? <IsLogin /> : <IsNotLogin />}
      </div> */}
    </div>
  )
}

export default Nav