import React, { createContext, useState, useContext } from 'react';

// Create a Context for booking data
const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const [bookingData, setBookingData] = useState(() => {
    // Load from local storage if available
    const savedData = localStorage.getItem('bookingData');
    return savedData ? JSON.parse(savedData) : [];
  });

  const updateBookingData = (newData) => {
    setBookingData((prevData) => {
      const updatedData = [...prevData, ...newData];
      localStorage.setItem('bookingData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const clearBookingData = () => {
    setBookingData([]);
    localStorage.removeItem('bookingData');
  };

  return (
    <BookingContext.Provider value={{ bookingData, updateBookingData, clearBookingData }}>
      {children}
    </BookingContext.Provider>
  );
};

export const useBooking = () => useContext(BookingContext);
